import PropTypes from 'prop-types';
import React from 'react';
import { AppLayout as AppLayoutStyled } from './AppLayout.styles';

const AppLayout = ({ children }) => <AppLayoutStyled className="app-layout">{children}</AppLayoutStyled>;

AppLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default AppLayout;
