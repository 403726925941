import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import RepoAvatar from './RepoAvatar';
import {
  RoundedRepoButtonGroup,
  RoundedRepoLabelButton,
  RoundedRepoLabelButtonContainer,
  RoundedRepoRemoveButton,
  RoundedRepoRemoveButtonContainer,
  RoundedRepoSyncButton,
  RoundedRepoSyncButtonContainer
} from './RoundedRepoButton.styles';

const RoundedRepoButton = ({ t, avatarUrl, name, onSyncClick, onRemoveClick }) => (
  <RoundedRepoButtonGroup variant="outlined" fullWidth>
    {/* label button */}
    <RoundedRepoLabelButton disableRipple disableFocusRipple>
      <RoundedRepoLabelButtonContainer>
        <RepoAvatar name={name} src={avatarUrl} sx={{ width: 24, height: 24 }} />
        <Typography noWrap variant="body1" component="p">
          {name}
        </Typography>
      </RoundedRepoLabelButtonContainer>
    </RoundedRepoLabelButton>
    {/* sync button */}
    <RoundedRepoSyncButton onClick={onSyncClick}>
      <RoundedRepoSyncButtonContainer>
        <SyncRoundedIcon sx={{ fontSize: 18 }} />
        <Typography noWrap variant="body2" component="p" sx={{ fontWeight: 600 }}>
          {t('settings.sync')}
        </Typography>
      </RoundedRepoSyncButtonContainer>
    </RoundedRepoSyncButton>
    {/* remove button */}
    <RoundedRepoRemoveButton onClick={onRemoveClick}>
      <RoundedRepoRemoveButtonContainer>
        <DeleteForeverRoundedIcon sx={{ fontSize: 20 }} />
        <Typography noWrap variant="body2" component="p" sx={{ fontWeight: 600 }}>
          {t('settings.remove')}
        </Typography>
      </RoundedRepoRemoveButtonContainer>
    </RoundedRepoRemoveButton>
  </RoundedRepoButtonGroup>
);

RoundedRepoButton.defaultProps = {
  avatarUrl: null
};

RoundedRepoButton.propTypes = {
  t: PropTypes.func.isRequired,
  avatarUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSyncClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired
};

export default withTranslation()(RoundedRepoButton);
