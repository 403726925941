import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Card, CardActions, CardContent, Dialog, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import validator from 'validator';
import { BP_SM } from '../../../../shared/constants';
import { isNilOrEmpty } from '../../../../shared/utils';
import { useScreenSizeDetector } from '../../hooks/useScreenSizeDetector';
import { FlexColumnContainer, FlexRowContainer } from '../../shared/Common.styles';
import RoundedTextField from '../../shared/RoundedTextField';

const ResetPasswordDialog = ({ t, onClose, open, defaultEmail }) => {
  const [emailValidationError, setEmailValidationError] = useState(false);
  const emailRef = useRef(null);
  const theme = useTheme();
  const isMobile = useScreenSizeDetector(BP_SM);

  const handleEmailChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    setEmailValidationError(false);
  };

  const handlePasswordResetButtonClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    const isValidEmail = validator.isEmail(emailRef.current.value);
    if (!isValidEmail) {
      setEmailValidationError(true);
    } else {
      onClose(emailRef.current.value);
    }
  };

  const handleDialogEnter = () => {
    setEmailValidationError(false);
  };

  const getAuthButtonErrorStyle = (error) => {
    const sx = { width: '100%' };
    if (error) {
      return {
        ...sx,
        '& .MuiFilledInput-root': {
          border: '2px solid rgba(193, 25, 21, 0.75)',
          backgroundColor: 'rgba(193, 25, 21, 0.10)'
        }
      };
    }
    return sx;
  };

  return (
    <Dialog
      data-dialog="reset-password"
      onClose={() => {
        onClose();
      }}
      fullWidth={isMobile}
      maxWidth="xs"
      open={open}
      TransitionProps={{ onEnter: handleDialogEnter }}
    >
      <form>
        <Card sx={{ padding: theme.spacing(2, 4) }}>
          <CardContent sx={{ padding: 0, margin: 0 }}>
            <FlexColumnContainer sx={{ alignItems: 'center' }}>
              <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: theme.spacing(3) }}>
                {t('auth.resetPassword')}
              </Typography>
              <Typography variant="body1" gutterBottom component="p" sx={{ marginBottom: theme.spacing(2) }}>
                {isNilOrEmpty(defaultEmail) ? t('auth.enterEmail') : t('auth.receiveEmail')}
              </Typography>
              {/* email field */}
              <RoundedTextField
                autoComplete="email"
                helperText={emailValidationError ? t('error.account.email') : ''}
                error={emailValidationError}
                inputRef={emailRef}
                name="email"
                onChange={handleEmailChange}
                defaultValue={defaultEmail}
                disabled={!isNilOrEmpty(defaultEmail)}
                data-input="email"
                sx={getAuthButtonErrorStyle(emailValidationError)}
              />
            </FlexColumnContainer>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <FlexRowContainer sx={{ justifyContent: 'space-evenly', width: '100%', marginTop: theme.spacing(1) }}>
              <Button
                autoFocus
                arial-label="reset"
                onClick={handlePasswordResetButtonClick}
                startIcon={<CheckRoundedIcon />}
                type="submit"
                data-button="reset"
                sx={{ width: '100%' }}
              >
                {t('auth.reset')}
              </Button>
              <Button
                autoFocus
                arial-label="cancel"
                onClick={() => {
                  onClose();
                }}
                startIcon={<CloseRoundedIcon />}
                data-button="cancel"
                sx={{ width: '100%' }}
              >
                {t('auth.cancel')}
              </Button>
            </FlexRowContainer>
          </CardActions>
        </Card>
      </form>
    </Dialog>
  );
};

ResetPasswordDialog.defaultProps = {
  defaultEmail: ''
};

ResetPasswordDialog.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  defaultEmail: PropTypes.string
};

export default withTranslation()(ResetPasswordDialog);
