import { Alert, Backdrop } from '@mui/material';
import { styled } from '@mui/system';

export const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
  color: theme.palette.offwhite.main
}));

export const AlertStyled = styled(Alert)(() => ({
  width: '100%',
  '& .MuiAlert-icon': {
    alignItems: 'center'
  },
  '& .MuiAlert-action': {
    alignItems: 'center'
  }
}));
