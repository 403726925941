import { Box, Toolbar as MuiToolbar, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { BP_MOBILE_LANDSCAPE, BP_MOBILE_PORTRAIT } from '../../../../shared/constants';

export const ToolbarText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Cabin',
  fontSize: '28px',
  fontWeight: 700,
  paddingLeft: theme.spacing(1),
  // styles for < mobile landscape
  [theme.breakpoints.down(BP_MOBILE_LANDSCAPE)]: {
    fontSize: '24px'
  }
}));

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  padding: theme.spacing(0, 4),
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down(BP_MOBILE_PORTRAIT)]: {
    padding: theme.spacing(0, 2)
  }
}));

export const ToolbarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'end',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(BP_MOBILE_PORTRAIT)]: {
      marginRight: theme.spacing(1)
    }
  }
}));
