import i18n from 'i18next';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_SUCCESS,
  COOKIE_KEY_OAUTH_RESPONSE,
  OAUTH_ACCESS_DENIED,
  OAUTH_FAILURE_ALREADY_REGISTERED,
  OAUTH_FAILURE_NOT_AUTHENTICATED,
  OAUTH_FAILURE_UNABLE_TO_REVOKE,
  OAUTH_REVOKED,
  OAUTH_SUCCESS
} from '../../../shared/constants';

const getOAuthResponse = () => {
  let resp;
  try {
    resp = JSON.parse(Cookies.get(COOKIE_KEY_OAUTH_RESPONSE) || '{}');
  } catch (e) {
    resp = {};
  } finally {
    Cookies.remove(COOKIE_KEY_OAUTH_RESPONSE);
  }
  return resp;
};

export const parseOAuthResponse = ({ type, provider }) => {
  if (type === OAUTH_FAILURE_NOT_AUTHENTICATED) {
    return {
      severity: ALERT_SEVERITY_ERROR,
      message: i18n.t('notification.oauthResponse.unauthenticated'),
      provider
    };
  }

  if (type === OAUTH_ACCESS_DENIED) {
    return {
      severity: ALERT_SEVERITY_ERROR,
      message: i18n.t('notification.oauthResponse.accessDenied', { provider: i18n.t(`common.${provider}`) }),
      provider
    };
  }

  if (type === OAUTH_FAILURE_UNABLE_TO_REVOKE) {
    return {
      severity: ALERT_SEVERITY_ERROR,
      message: i18n.t('notification.oauthResponse.unableToRevokeAccessToken', { provider: i18n.t(`common.${provider}`) }),
      provider
    };
  }

  if (type === OAUTH_FAILURE_ALREADY_REGISTERED) {
    return {
      severity: ALERT_SEVERITY_ERROR,
      message: i18n.t('notification.oauthResponse.alreadyRegistered', { provider: i18n.t(`common.${provider}`) }),
      provider
    };
  }

  if (type === OAUTH_REVOKED) {
    return {
      severity: ALERT_SEVERITY_SUCCESS,
      message: i18n.t('notification.oauthResponse.revoked', { provider: i18n.t(`common.${provider}`) }),
      provider
    };
  }

  if (type === OAUTH_SUCCESS) {
    return {
      severity: ALERT_SEVERITY_SUCCESS,
      message: i18n.t('notification.oauthResponse.success', { provider: i18n.t(`common.${provider}`) }),
      provider
    };
  }

  return {
    severity: ALERT_SEVERITY_ERROR,
    message: i18n.t('notification.somethingWentWrong')
  };
};

export const useOAuthResponse = (handler) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      return;
    }
    setChecked(true);

    const resp = getOAuthResponse();
    if (resp) {
      handler(resp);
    }
  }, [checked, handler]);
};
