import axios from 'axios';
import i18n from 'i18next';
import { AXIOS_CONFIG } from '../../../../../shared/constants';
import RepoAccessError from '../../../../shared/errors/RepoAccessError';
import ThunkError from '../../../../shared/errors/ThunkError';
import { set as setFeedback } from '../../../reducers/feedback';

const listRepo = (id, provider) => async (dispatch) => {
  const instance = axios.create(AXIOS_CONFIG);

  try {
    await dispatch(setFeedback(null));
    const resp = await instance.get(`/api/u/${id}/repo`, {
      params: {
        provider
      }
    });
    const { data } = resp;
    return data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      const { status, data: message } = e.response;
      if (status === 401 || status === 403) {
        throw new RepoAccessError();
      }
      // re-throw the error
      throw new ThunkError(message);
    }
    throw new ThunkError(i18n.t('error.async.unableToListRepos', { provider: i18n.t(`common.${provider}`) }));
  }
};

export default listRepo;
