import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserNotFound from '../../../server/errors/UserNotFound';
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  ALERT_SEVERITY_SUCCESS,
  BP_MD,
  BP_MOBILE_PORTRAIT,
  BP_XL
} from '../../../shared/constants';
import { isNilOrEmpty } from '../../../shared/utils';
import RepoAccessError from '../../shared/errors/RepoAccessError';
import PageNotFound from '../error/PageNotFound';
import { withAuth } from '../hocs/withAuth';
import { withProgress } from '../hocs/withProgress';
import { withSnackbar } from '../hocs/withSnackbar';
import { withUsername } from '../hocs/withUsername';
import { parseOAuthResponse, useOAuthResponse } from '../hooks/useOAuthResponse';
import { useScreenSizeDetector } from '../hooks/useScreenSizeDetector';
import { useThunk } from '../hooks/useThunk';
import AlertBanner from '../shared/AlertBanner';
import { PageContainer, PageContentContainer } from '../shared/Common.styles';
import ContributionCalendar from './contribution-calendar/ContributionCalendar';
import ContributionDialog from './contribution-dialog/ContributionDialog';
import { getPreferredThemeIndex, THEMES } from './contribution-legend/ContributionLegendList';
import ProfileHeader from './profile-header/ProfileHeader';
import ProfileRepoFilter from './profile-repo-filter/ProfileRepoFilter';
import RepoDialog from './repo-dialog/RepoDialog';
import RepoHeader from './repo-header/RepoHeader';
import { ContributionCalendarContainer, ContributionCalendarRootContainer, UserPageRootContainer } from './UserPage.styles';

// dayjs plugins
dayjs.extend(timezone);
dayjs.extend(utc);

// TODO
// [ ] user can only list repos when they are self authorized
// [ ] modal dialog pop up with the list of available repos / added repos
// [ ] new endpoint to
//     [ ] add repo to table
//     [ ] get all commit info
//     [ ] create webhook for future commits

// data model for repo
// store added repo in db
// store added repo logs in db
// add webhook endpoint to update the db entry

const UserPage = (props) => {
  const { auth, t, username, setProgress, setSnackbar, closeSnackbar, getUserRepo, listRepo, postUserRepoLogs } = props;
  const [state, setState] = useState({
    repos: [],
    name: null,
    avatarUrl: null,
    data: [],
    index: 0,
    repoDialogOpen: false,
    repoDialogStartListingFrom: null,
    contributionDialog: { open: false },
    error: null,
    userNotFound: false,
    oauthResponse: null
  });
  const [theme, setTheme] = useState(THEMES[getPreferredThemeIndex()]);
  const navigate = useNavigate();
  const isUnderXL = useScreenSizeDetector(BP_XL);
  const isUnderMobileSize = useScreenSizeDetector(BP_MOBILE_PORTRAIT);
  const isUnderTabletSize = useScreenSizeDetector(BP_MD);
  const selfAuthorized = auth.isAuthenticated && username === auth.username;

  const getParams = useCallback(
    () => ({
      thunk: username ? getUserRepo : null,
      params: username,
      setProgress,
      options: {
        onSuccess: (data) => {
          const { name, avatarUrl, repos, oauths } = data;
          if (repos && repos.length > 1) {
            repos.unshift({
              name: t('userPage.all'),
              pathWithNamespace: t('userPage.all'),
              firstActivityAt: dayjs().utc().unix()
            });
          }
          if (repos && repos.length > 0) {
            // set the parsed logs in the state
            setState((pstate) => ({
              ...pstate,
              name,
              avatarUrl,
              data: repos,
              oauths,
              error: null,
              userNotFound: false
            }));
          } else {
            setState((pstate) => ({
              ...pstate,
              name,
              avatarUrl,
              data: [],
              oauths,
              error: null,
              userNotFound: false
            }));
          }
        },
        onFailure: (e) => {
          if (e instanceof UserNotFound) {
            setState((pstate) => ({
              ...pstate,
              userNotFound: true
            }));
          } else {
            setState((pstate) => ({
              ...pstate,
              error: e.message
            }));
          }
        }
      }
    }),
    [t, getUserRepo, username, setProgress]
  );

  useOAuthResponse((response) => {
    if (isNilOrEmpty(response)) {
      // no oauth response found
      return;
    }
    // process oauth response in case user linked their repo provider
    const resp = parseOAuthResponse(response);
    setState((pstate) => ({
      ...pstate,
      oauthResponse: resp,
      repoDialogOpen: resp.severity === ALERT_SEVERITY_SUCCESS,
      repoDialogStartListingFrom: resp.severity === ALERT_SEVERITY_SUCCESS ? resp.provider : null
    }));
  });

  // Use useCallback to keep the function reference identical during render cycle to ensure
  // it's executed only once per render & null thunk is passwed to ignore when not authenticated
  const isThunkCalled = useThunk(getParams);

  const onEditProfileClick = () => {
    navigate(t('path.frontend.settings'));
  };

  const onAddRepoClick = () => {
    setState((pstate) => ({
      ...pstate,
      repoDialogOpen: true,
      repoDialogStartListingFrom: null,
      error: null
    }));
  };

  const linkConfirmationSnackbar = (provider, callback) => () => {
    setSnackbar({
      severity: ALERT_SEVERITY_INFO,
      message: t('notification.oauth.linkConfirmation', { provider }),
      action: {
        text: t('userPage.authorise'),
        icon: <LoginRoundedIcon />,
        callback
      }
    });
  };

  const getRepos = (provider, onSuccess, onFailure) => {
    setProgress(true);
    listRepo(auth.id, provider)
      .then((data) => {
        if (onSuccess) {
          onSuccess({
            provider,
            data
          });
        }
      })
      .catch((e) => {
        if (onFailure) {
          onFailure(e);
        }
        if (e instanceof RepoAccessError) {
          // show link confirmation and re-direct to provider signin page
          linkConfirmationSnackbar(t(`common.${provider}`), () => {
            window.location.href = `${t(`path.backend.auth.${provider}.signin`)}?redirectTo=/${username}`;
          })();
        } else {
          setState((pstate) => ({
            ...pstate,
            repoDialogOpen: false,
            error: e.message
          }));
        }
      })
      .finally(() => {
        setProgress(false);
      });
  };

  const onRepoDialogClose = () => {
    closeSnackbar();
    setState((pstate) => ({
      ...pstate,
      repoDialogOpen: false,
      repoDialogStartListingFrom: null
    }));
  };

  const handleGetRepoLogs = (repo) => {
    onRepoDialogClose();
    setProgress(true);
    postUserRepoLogs({ uid: auth.id, ...repo })
      .then((data) => {
        if (!isNilOrEmpty(data)) {
          setState((pstate) => {
            if (pstate.data.length === 1) {
              return {
                ...pstate,
                data: [
                  {
                    name: t('userPage.all'),
                    pathWithNamespace: t('userPage.all'),
                    firstActivityAt: dayjs().utc().unix()
                  },
                  ...state.data,
                  data
                ],
                repoDialogOpen: false,
                error: null
              };
            }
            return {
              ...pstate,
              data: [...state.data, data],
              repoDialogOpen: false,
              error: null
            };
          });
        }
      })
      .catch((e) => {
        setState((pstate) => ({
          ...pstate,
          repoDialogOpen: false,
          error: e.message
        }));
      })
      .finally(() => {
        setProgress(false);
      });
  };

  const onFilterClick = (index) => () => {
    setState((pstate) => ({
      ...pstate,
      index
    }));
  };

  const changeTheme = (selectedTheme) => {
    setTheme(selectedTheme);
  };

  const openContributionDialog = (title, commits) => {
    setState((pstate) => ({ ...pstate, contributionDialog: { open: true, title, commits } }));
  };

  const closeContributionDialog = () => {
    setState((pstate) => ({ ...pstate, contributionDialog: { ...pstate.contributionDialog, open: false } }));
  };

  return (
    <>
      {isThunkCalled && state.userNotFound && <PageNotFound />}
      {isThunkCalled && !state.userNotFound && (
        <PageContainer>
          <PageContentContainer>
            {state.error && <AlertBanner gutterBottom message={state.error} severity={ALERT_SEVERITY_ERROR} />}
            {state.oauthResponse && (
              <AlertBanner gutterBottom message={state.oauthResponse.message} severity={state.oauthResponse.severity} />
            )}
            {isThunkCalled && (
              <RepoDialog
                open={state.repoDialogOpen}
                startListingFrom={state.repoDialogStartListingFrom}
                onClose={onRepoDialogClose}
                username={username}
                oauths={state.oauths}
                existingRepos={state.data.map(({ repoId, provider }) => ({ id: repoId, provider }))}
                getRepos={getRepos}
                onClick={handleGetRepoLogs}
                linkConfirmationSnackbar={linkConfirmationSnackbar}
              />
            )}
            {isThunkCalled && (
              <ContributionDialog
                onClose={closeContributionDialog}
                open={state.contributionDialog.open}
                title={state.contributionDialog.title}
                commits={state.contributionDialog.commits}
              />
            )}
            {isThunkCalled && (
              <UserPageRootContainer>
                {isUnderXL ? (
                  <>
                    <ProfileHeader
                      name={state.name}
                      username={username}
                      avatarUrl={state.avatarUrl}
                      onEditProfileClick={onEditProfileClick}
                      onAddRepoClick={onAddRepoClick}
                      selfAuthorized={selfAuthorized}
                      limitAddRepo={!auth.hasSubscription && state.data.length >= 1}
                    />
                    {!isNilOrEmpty(state.data) && state.data.length > 1 && (
                      <ProfileRepoFilter data={state.data} index={state.index} onFilterClick={onFilterClick} />
                    )}
                    <ContributionCalendarRootContainer>
                      {((state.data.length > 1 && state.index > 0) || state.data.length === 1) && (
                        <RepoHeader {...state.data[state.index]} selfAuthorized={selfAuthorized} />
                      )}
                      <ContributionCalendarContainer>
                        {isNilOrEmpty(state.data) ? (
                          <ContributionCalendar
                            data={[{ logs: [] }]}
                            index={0}
                            theme={theme}
                            changeTheme={changeTheme}
                            isUnderMobileSize={isUnderMobileSize}
                            isUnderTabletSize={isUnderTabletSize}
                            openContributionDialog={openContributionDialog}
                          />
                        ) : (
                          <ContributionCalendar
                            data={state.data}
                            index={state.index}
                            theme={theme}
                            changeTheme={changeTheme}
                            isUnderMobileSize={isUnderMobileSize}
                            isUnderTabletSize={isUnderTabletSize}
                            openContributionDialog={openContributionDialog}
                          />
                        )}
                      </ContributionCalendarContainer>
                    </ContributionCalendarRootContainer>
                  </>
                ) : (
                  <>
                    <ProfileHeader
                      name={state.name}
                      username={username}
                      avatarUrl={state.avatarUrl}
                      onEditProfileClick={onEditProfileClick}
                      onAddRepoClick={onAddRepoClick}
                      selfAuthorized={selfAuthorized}
                      limitAddRepo={!auth.hasSubscription && state.data.length >= 1}
                    />
                    <ContributionCalendarRootContainer>
                      {((state.data.length > 1 && state.index > 0) || state.data.length === 1) && (
                        <RepoHeader {...state.data[state.index]} selfAuthorized={selfAuthorized} />
                      )}
                      <ContributionCalendarContainer>
                        {isNilOrEmpty(state.data) ? (
                          <ContributionCalendar
                            data={[{ logs: [] }]}
                            index={0}
                            theme={theme}
                            changeTheme={changeTheme}
                            isUnderMobileSize={isUnderMobileSize}
                            isUnderTabletSize={isUnderTabletSize}
                            openContributionDialog={openContributionDialog}
                          />
                        ) : (
                          <ContributionCalendar
                            data={state.data}
                            index={state.index}
                            theme={theme}
                            changeTheme={changeTheme}
                            isUnderMobileSize={isUnderMobileSize}
                            isUnderTabletSize={isUnderTabletSize}
                            openContributionDialog={openContributionDialog}
                          />
                        )}
                      </ContributionCalendarContainer>
                    </ContributionCalendarRootContainer>
                    {!isNilOrEmpty(state.data) && state.data.length > 1 && (
                      <ProfileRepoFilter data={state.data} index={state.index} onFilterClick={onFilterClick} />
                    )}
                  </>
                )}
              </UserPageRootContainer>
            )}
          </PageContentContainer>
        </PageContainer>
      )}
    </>
  );
};

UserPage.defaultProps = {
  auth: {
    id: null,
    username: '',
    isAuthenticated: false,
    hasSubscription: false
  }
};

UserPage.propTypes = {
  auth: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    hasSubscription: PropTypes.bool
  }),
  t: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  setProgress: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  getUserRepo: PropTypes.func.isRequired,
  listRepo: PropTypes.func.isRequired,
  postUserRepoLogs: PropTypes.func.isRequired
};

export default withSnackbar()(withProgress()(withAuth()(withTranslation()(withUsername()(UserPage)))));
