import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const Copyright = ({ t }) => (
  <Typography variant="caption" component="span">
    {t('copyright')}
  </Typography>
);

Copyright.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Copyright);
