import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Logo as LogoStyled } from './Logo.styles';

const Logo = ({ auth: { username, isAuthenticated }, t }) => {
  const navigate = useNavigate();

  const onLogoClick = () => {
    navigate(isAuthenticated ? `/${username}` : t('path.root'));
  };

  return (
    <LogoStyled onClick={onLogoClick}>
      <Avatar variant="square" src={t('logo.src')} alt={t('logo.alt')} sx={{ width: '32px', height: '32px' }} />
    </LogoStyled>
  );
};

Logo.defaultProps = {
  auth: {
    username: '',
    isAuthenticated: false
  }
};

Logo.propTypes = {
  auth: PropTypes.shape({
    username: PropTypes.string,
    isAuthenticated: PropTypes.bool
  }),
  t: PropTypes.func.isRequired
};

export default withTranslation()(Logo);
