import PropTypes from 'prop-types';
import React from 'react';
import { isTouchDevice } from '../../../shared/utils';
import { Link as LinkStyled, Typography as TypographyStyled } from './Link.styles';

const getHrefLink = (text, href, openNewTab) =>
  openNewTab ? (
    <LinkStyled href={href} component="a" target="_blank" rel="noopener noreferrer">
      {text}
    </LinkStyled>
  ) : (
    <LinkStyled href={href} component="a">
      {text}
    </LinkStyled>
  );

const getOnClickLink = (text, onClick) => <LinkStyled onClick={onClick}>{text}</LinkStyled>;

const textDecoration = (underline) => {
  if (underline) {
    return 'underline';
  }
  return isTouchDevice() ? 'underline' : 'none';
};

const Link = ({ href, onClick, text, openNewTab, gutterBottom, variant, sx, underline }) => (
  <TypographyStyled
    gutterBottom={gutterBottom}
    variant={variant}
    component="span"
    sx={{
      ...sx,
      '&:hover': { cursor: 'pointer' },
      textDecoration: textDecoration(underline)
    }}
  >
    {href && getHrefLink(text, href, openNewTab)}
    {!href && onClick && getOnClickLink(text, onClick)}
  </TypographyStyled>
);

Link.defaultProps = {
  href: null,
  onClick: null,
  openNewTab: false,
  gutterBottom: false,
  variant: 'caption',
  sx: {},
  underline: false
};

Link.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  openNewTab: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  variant: PropTypes.string,
  sx: PropTypes.shape({}),
  underline: PropTypes.bool
};

export default Link;
