import i18n from 'i18next';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_WARNING,
  COOKIE_KEY_SUBSCRIPTION_RESPONSE,
  EMAIL_ADDRESS,
  SUBSCRIPTION_CANCELED_CHECKOUT,
  SUBSCRIPTION_FAILURE_BILLING_PORTAL,
  SUBSCRIPTION_FAILURE_CHECKOUT,
  SUBSCRIPTION_FAILURE_ERROR_IN_SUCCESS,
  SUBSCRIPTION_FAILURE_NO_SESSION_ID,
  SUBSCRIPTION_FAILURE_NO_SUBSCRIPTION_DATA,
  SUBSCRIPTION_SUCCESS_CHECKOUT
} from '../../../shared/constants';

const getSubscriptionResponse = () => {
  let resp;
  try {
    resp = JSON.parse(Cookies.get(COOKIE_KEY_SUBSCRIPTION_RESPONSE) || '{}');
  } catch (e) {
    resp = {};
  } finally {
    Cookies.remove(COOKIE_KEY_SUBSCRIPTION_RESPONSE);
  }
  return resp;
};

export const parseSubscriptionResponse = ({ type }) => {
  switch (type) {
    case SUBSCRIPTION_SUCCESS_CHECKOUT:
      return {
        severity: ALERT_SEVERITY_SUCCESS,
        message: i18n.t('notification.subscriptionResponse.success')
      };
    case SUBSCRIPTION_CANCELED_CHECKOUT:
      return {
        severity: ALERT_SEVERITY_WARNING,
        message: i18n.t('notification.subscriptionResponse.canceled')
      };
    case SUBSCRIPTION_FAILURE_CHECKOUT:
      return {
        severity: ALERT_SEVERITY_ERROR,
        message: i18n.t('notification.subscriptionResponse.unableToCreateSubscriptionCheckout', { email: EMAIL_ADDRESS })
      };
    case SUBSCRIPTION_FAILURE_NO_SESSION_ID:
      return {
        severity: ALERT_SEVERITY_ERROR,
        message: i18n.t('notification.subscriptionResponse.unableToProcessCheckout', { email: EMAIL_ADDRESS })
      };
    case SUBSCRIPTION_FAILURE_ERROR_IN_SUCCESS:
      return {
        severity: ALERT_SEVERITY_ERROR,
        message: i18n.t('notification.subscriptionResponse.unableToProcessCheckout', { email: EMAIL_ADDRESS })
      };
    case SUBSCRIPTION_FAILURE_NO_SUBSCRIPTION_DATA:
      return {
        severity: ALERT_SEVERITY_ERROR,
        message: i18n.t('notification.subscriptionResponse.unableToProcessCheckout', { email: EMAIL_ADDRESS })
      };
    case SUBSCRIPTION_FAILURE_BILLING_PORTAL:
      return {
        severity: ALERT_SEVERITY_ERROR,
        message: i18n.t('notification.subscriptionResponse.unableToOpenSubscriptionPortal', { email: EMAIL_ADDRESS })
      };
  }

  return {
    message: i18n.t('notification.somethingWentWrong'),
    severity: ALERT_SEVERITY_ERROR
  };
};

export const useSubscriptionResponse = (handler) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      return;
    }
    setChecked(true);

    const resp = getSubscriptionResponse();
    if (resp) {
      handler(resp);
    }
  }, [checked, handler]);
};
