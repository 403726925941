import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { BP_MD, BP_MOBILE_LANDSCAPE, BP_XL } from '../../../shared/constants';

export const UserPageRootContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down(BP_XL)]: {
    flexDirection: 'column'
  },
  '& >:not(:last-child)': {
    [theme.breakpoints.down(BP_MD)]: {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.between(BP_MD, BP_XL)]: {
      marginBottom: theme.spacing(4)
    },
    [theme.breakpoints.up(BP_XL)]: {
      marginRight: theme.spacing(4)
    }
  }
}));

export const ContributionCalendarRootContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& >:not(:last-child)': {
    [theme.breakpoints.down(BP_MD)]: {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.between(BP_MD, BP_XL)]: {
      marginBottom: theme.spacing(4)
    },
    [theme.breakpoints.up(BP_XL)]: {
      marginBottom: theme.spacing(4)
    }
  }
}));

export const ContributionCalendarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  '& >:not(:last-child)': {
    marginBottom: theme.spacing(2)
  },
  [theme.breakpoints.down(BP_MOBILE_LANDSCAPE)]: {
    alignItems: 'center'
  }
}));
