import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import ProviderButton from './ProviderButton';
import { ProviderListContainer } from './ProviderList.styles';

const ProviderList = ({ t, gitlab, github, unlinkConfirmationSnackbar }) => (
  <ProviderListContainer>
    <ProviderButton
      text={gitlab ? t('settings.unlink') : t('settings.link')}
      iconSrc={t('auth.gitlabOAuth.iconSrc')}
      iconAlt={t('auth.gitlabOAuth.iconAlt')}
      onClick={() => {
        if (gitlab) {
          unlinkConfirmationSnackbar(t('common.gitlab'), () => {
            window.location.href = t('path.backend.auth.gitlab.signout');
          })();
        } else {
          window.location.href = t('path.backend.auth.gitlab.signin');
        }
      }}
    />
    <ProviderButton
      text={github ? t('settings.unlink') : t('settings.link')}
      iconSrc={t('auth.githubOAuth.iconSrc')}
      iconAlt={t('auth.githubOAuth.iconAlt')}
      onClick={() => {
        if (github) {
          unlinkConfirmationSnackbar(t('common.github'), () => {
            window.location.href = t('path.backend.auth.github.signout');
          })();
        } else {
          window.location.href = t('path.backend.auth.github.signin');
        }
      }}
    />
  </ProviderListContainer>
);

ProviderList.defaultProps = {
  gitlab: false,
  github: false
};

ProviderList.propTypes = {
  t: PropTypes.func.isRequired,
  gitlab: PropTypes.bool,
  github: PropTypes.bool,
  unlinkConfirmationSnackbar: PropTypes.func.isRequired
};

export default withTranslation()(ProviderList);
