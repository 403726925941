import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { withAuth } from '../hocs/withAuth';
import { PageContainer, PageContentContainer } from '../shared/Common.styles';
import Link from '../shared/Link';
import { ErrorPageContentContainer, ErrorPageHeader } from './ErrorPage.styles';

const PageNotFound = ({ auth: { username, isAuthenticated }, t }) => {
  const navigate = useNavigate();

  const onBackToReporayClick = () => {
    const rootPath = t('path.root');
    if (isAuthenticated) {
      navigate(`${rootPath}${username}`);
    } else {
      navigate(rootPath);
    }
  };

  return (
    <PageContainer sx={{ marginTop: '15vh', marginBottom: '20vh' }}>
      <PageContentContainer>
        <ErrorPageHeader variant="h5" gutterBottom sx={{ color: 'rgb(0, 0, 0, 0.75)' }}>
          {t('error.pageNotFound')}
        </ErrorPageHeader>
        <ErrorPageContentContainer>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            onClick={onBackToReporayClick}
            text={t('error.backToReporay')}
            gutterBottom
            variant="body1"
            sx={{ fontWeight: '600', color: 'rgb(0, 0, 0, 0.5)' }}
          />
          {/* eslint-enable-next-line jsx-a11y/anchor-is-valid */}
        </ErrorPageContentContainer>
      </PageContentContainer>
    </PageContainer>
  );
};

PageNotFound.defaultProps = {
  auth: {
    username: '',
    isAuthenticated: false
  }
};

PageNotFound.propTypes = {
  auth: PropTypes.shape({
    username: PropTypes.string,
    isAuthenticated: PropTypes.bool
  }),
  t: PropTypes.func.isRequired
};

export default withTranslation()(withAuth()(PageNotFound));
