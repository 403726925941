import { Button, Divider, InputAdornment, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import validator from 'validator';
import { ALERT_SEVERITY_ERROR, ALERT_SEVERITY_SUCCESS } from '../../../shared/constants';
import { isNilOrEmpty } from '../../../shared/utils';
import { withProgress } from '../hocs/withProgress';
import AlertBanner from '../shared/AlertBanner';
import { FlexColumnContainer, PageContainer, PageContentContainer } from '../shared/Common.styles';
import RoundedButton from '../shared/RoundedButton';
import RoundedTextField from '../shared/RoundedTextField';

const PasswordResetPage = ({ t, setProgress, resetPassword }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidationError, setPasswordValidationError] = useState(false);
  const passwordRef = useRef(null);
  const theme = useTheme();
  const { id: resetCode } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const email = searchParams.get('email');
  const [snackbar, setSnackbar] = useState(
    !isNilOrEmpty(email) && validator.isEmail(email)
      ? null
      : { severity: ALERT_SEVERITY_ERROR, message: t('passwordResetPage.invalidLink') }
  );

  const handlePasswordChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPasswordValidationError(false);
  };

  const handleShowPassword = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowPassword(!showPassword);
  };

  const signinCustomAction = () => (
    <Button color="inherit" size="small" onClick={() => navigate(t('path.frontend.signin'))}>
      {t('auth.signin')}
    </Button>
  );

  const handleResetButtonClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    // ensure that password is at least 8 char long
    const isPasswordStrong = validator.isStrongPassword(passwordRef.current.value);
    if (!isPasswordStrong) {
      setPasswordValidationError(true);
    } else {
      setProgress(true);
      resetPassword({
        email,
        resetCode,
        password: passwordRef.current.value
      })
        .then(() => {
          setSnackbar({
            message: t('notification.passwordResetSuccess'),
            severity: ALERT_SEVERITY_SUCCESS,
            customAction: signinCustomAction()
          });
        })
        .catch((err) => {
          setSnackbar({
            message: err.message,
            severity: ALERT_SEVERITY_ERROR
          });
        })
        .finally(() => {
          setProgress(false);
        });
    }
  };

  const getAuthButtonErrorStyle = (error) => {
    if (error) {
      return {
        '& .MuiFilledInput-root': {
          border: '2px solid rgba(193, 25, 21, 0.75)',
          backgroundColor: 'rgba(193, 25, 21, 0.10)'
        }
      };
    }
    return {};
  };

  return (
    <PageContainer>
      <PageContentContainer>
        {snackbar && (
          <AlertBanner
            gutterBottom
            message={snackbar.message}
            severity={snackbar.severity}
            shouldHaveCustomAction={!!snackbar.customAction}
            customAction={snackbar.customAction}
          />
        )}
        {!isNilOrEmpty(email) && (
          <form>
            <FlexColumnContainer sx={{ alignItems: 'center' }}>
              {/* password reset header */}
              <Typography variant="h5" gutterBottom sx={{ fontWeight: '600', marginBottom: theme.spacing(1) }}>
                {t('passwordResetPage.resetYourPassword')}
              </Typography>

              {/* email associated with the password being reset */}
              <Typography
                variant="body1"
                sx={{ fontWeight: '600', color: 'rgb(0, 0, 0, 0.5)' }}
              >
                {t('passwordResetPage.passwordBeingReset', { email })}
              </Typography>

              {/* divider with list of providers */}
              <Divider flexItem sx={{ opacity: '0.5', fontSize: 'small', margin: theme.spacing(6, 0) }} />

              <FlexColumnContainer sx={{ marginBottom: theme.spacing(2), width: '100%' }}>
                <Typography variant="h6" sx={{ fontWeight: '600' }}>
                  {t('common.newPassword')}
                </Typography>
                <RoundedTextField
                  type={showPassword ? 'text' : 'password'}
                  helperText={passwordValidationError ? t('error.account.signupPassword') : ''}
                  error={passwordValidationError}
                  inputRef={passwordRef}
                  name="password"
                  data-input="password"
                  autoComplete="current-password"
                  sx={getAuthButtonErrorStyle(passwordValidationError)}
                  onChange={handlePasswordChange}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disableFocusRipple
                          disableRipple
                          onClick={handleShowPassword}
                          sx={{
                            textTransform: 'none',
                            color: '#000',
                            fontWeight: '600',
                            '&:hover': { backgroundColor: 'inherit' }
                          }}
                        >
                          {showPassword ? t('auth.hide') : t('auth.show')}
                        </Button>
                      </InputAdornment>
                    )
                  }}
                />
              </FlexColumnContainer>

              <RoundedButton
                text={t('auth.reset')}
                sx={{ margin: theme.spacing(2, 0) }}
                dataButton="reset"
                submit
                onClick={handleResetButtonClick}
              />
            </FlexColumnContainer>
          </form>
        )}
      </PageContentContainer>
    </PageContainer>
  );
};

PasswordResetPage.propTypes = {
  t: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired
};

export default withProgress()(withTranslation()(PasswordResetPage));
