import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const LinkList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(0.5)
  }
}));
