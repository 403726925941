import axios from 'axios';
import i18n from 'i18next';
import { AXIOS_CONFIG } from '../../../../shared/constants';
import { set as setFeedback } from '../../reducers/feedback';
import { getErrorFeedback } from '../../utils';

const patchUserSettings = (id) => async (dispatch, getState) => {
  const state = getState();
  const {
    user: { data }
  } = state;
  const {
    user: { username, email, name }
  } = data;
  const instance = axios.create(AXIOS_CONFIG);
  const actions = [];

  try {
    await dispatch(setFeedback(null));
    await instance.patch(`/api/u/${id}/settings`, {
      data: {
        username,
        email,
        name
      }
    });
  } catch (e) {
    const feedback = getErrorFeedback(e, i18n.t('error.async.unableToUpdateUser'));
    // set error feedback state
    actions.push(dispatch(setFeedback(feedback)));
    // then re-throw the error
    throw new Error(feedback.message);
  }
  Promise.all(actions).then(() => {});
};

export default patchUserSettings;
