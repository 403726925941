import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isProductionMode } from '../shared/utils';
import en from './translation.en.json';

const DETECTION_OPTIONS = {
  order: ['querystring', 'localStorage', 'navigator'],
  caches: ['localStorage']
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: !isProductionMode(),
    load: 'languageOnly',
    detection: DETECTION_OPTIONS,
    // lng: navigator.language || navigator.userLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    resources: {
      en: {
        translation: en
      }
    }
  });

export default i18n;
