import { Backdrop, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

export const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
  color: theme.palette.offwhite.main
}));

export const CircularProgressStyled = styled(CircularProgress)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10)
}));
