import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { isTouchDevice } from '../../../../shared/utils';
import Link from '../../shared/Link';

const Credits = ({ t }) => (
  <Typography variant="caption" component="span" sx={{ textAlign: 'center', lineHeight: 'normal' }}>
    {t('credits.designedBy')}
    &nbsp;
    <Link
      href={t('credits.author.freepik.href')}
      text={t('credits.author.freepik.name')}
      openNewTab
      sx={{ lineHeight: 'normal'}}
    />
    &nbsp;
    {t('credits.and')}
    &nbsp;
    <Link
      href={t('credits.author.vitaly.href')}
      text={t('credits.author.vitaly.name')}
      openNewTab
      sx={{ lineHeight: 'normal' }}
    />
    .
  </Typography>
);

Credits.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Credits);
