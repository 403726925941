import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FlexColumnContainer } from '../../shared/Common.styles';
import { RepoProviderAvatar } from './RepoProvider.styles';

const RepoProvider = ({ src, alt }) => (
  <FlexColumnContainer sx={{ alignItems: 'center' }}>
    <RepoProviderAvatar variant="square" src={src} alt={alt} />
    <Typography noWrap gutterBottom variant="overline" component="p">
      {alt}
    </Typography>
  </FlexColumnContainer>
);

RepoProvider.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default RepoProvider;
