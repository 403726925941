import PropTypes from 'prop-types';
import React from 'react';
import { IconButton as IconButtonStyled } from './IconButton.styles';

const IconButton = ({ href, icon }) => (
  <IconButtonStyled href={href} target="_blank" rel="noopener noreferrer">
    {icon}
  </IconButtonStyled>
);

IconButton.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired
};

export default IconButton;
