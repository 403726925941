import PropTypes from 'prop-types';
import React from 'react';
import { PageContainer as PageContainerStyled } from './PageContainer.styles';

const PageContainer = ({ children }) => <PageContainerStyled bgcolor="background.default">{children}</PageContainerStyled>;

PageContainer.propTypes = {
  children: PropTypes.shape({}).isRequired
};

export default PageContainer;
