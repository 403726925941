import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { GITHUB, GITLAB, VISIBILITY_INTERNAL, VISIBILITY_PRIVATE } from '../../../../shared/constants';
import { isNilOrEmpty } from '../../../../shared/utils';
import { FlexColumnContainer } from '../../shared/Common.styles';
import IconButton from '../../shared/IconButton';
import RepoAvatar from '../../shared/RepoAvatar';
import { RepoHeaderNameContainer, RepoHeaderRootContainer } from './RepoHeader.styles';

const sx = {
  width: 18,
  height: 18
};

const GITLAB_ICON = <Avatar src="/images/login_gitlab.png" sx={sx} />;

const GITHUB_ICON = <Avatar src="/images/login_github.png" sx={sx} />;

const isRepoPrivate = (visibility) => VISIBILITY_PRIVATE === visibility || VISIBILITY_INTERNAL === visibility;

const RepoHeader = ({ selfAuthorized, name, provider, description, webUrl, avatarUrl, visibility }) => (
  <RepoHeaderRootContainer>
    <RepoAvatar name={name} src={avatarUrl} />
    <FlexColumnContainer>
      <RepoHeaderNameContainer>
        <Typography variant="body1" sx={{ lineHeight: 'unset' }}>
          {name}
        </Typography>
        {isRepoPrivate(visibility) ? (
          <LockOutlinedIcon sx={{ ...sx, opacity: 0.7 }} />
        ) : (
          <PublicRoundedIcon sx={{ ...sx, opacity: 0.7 }} />
        )}
        {!isNilOrEmpty(webUrl) && provider === GITLAB && (!isRepoPrivate(visibility) || selfAuthorized) && (
          <IconButton href={webUrl} icon={GITLAB_ICON} />
        )}
        {!isNilOrEmpty(webUrl) && provider === GITHUB && (!isRepoPrivate(visibility) || selfAuthorized) && (
          <IconButton href={webUrl} icon={GITHUB_ICON} />
        )}
        {/* <FlexRowContainer>
          <InsertLinkOutlinedIcon color="primary" sx={{ transform: 'rotate(135deg)' }} />
          <Link
            component="a"
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
            color="primary"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography noWrap variant="body2" component="p">
              reporay.com
            </Typography>
          </Link>
        </FlexRowContainer> */}
      </RepoHeaderNameContainer>
      <Typography variant="body2" sx={{ lineHeight: 'unset' }}>
        {description}
      </Typography>
    </FlexColumnContainer>
  </RepoHeaderRootContainer>
);

RepoHeader.defaultProps = {
  selfAuthorized: false,
  description: '',
  webUrl: '',
  avatarUrl: '',
  visibility: VISIBILITY_PRIVATE
};

RepoHeader.propTypes = {
  selfAuthorized: PropTypes.bool,
  name: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  description: PropTypes.string,
  webUrl: PropTypes.string,
  avatarUrl: PropTypes.string,
  visibility: PropTypes.string
};

export default RepoHeader;
