import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { BP_MOBILE_LANDSCAPE } from '../../../../shared/constants';
import { isNilOrEmpty } from '../../../../shared/utils';

const DrawerListItem = ({ icon, text, href, onClick, children }) => {
  const theme = useTheme();
  const sx = {
    marginRight: theme.spacing(16),
    [theme.breakpoints.down(BP_MOBILE_LANDSCAPE)]: {
      marginRight: 0
    }
  };

  if (!isNilOrEmpty(href)) {
    return (
      <ListItem button component="a" href={href} sx={sx}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {children}
      </ListItem>
    );
  }
  if (!isNilOrEmpty(onClick)) {
    return (
      <ListItem button onClick={onClick} sx={sx}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {children}
      </ListItem>
    );
  }
  return null;
};

DrawerListItem.defaultProps = {
  icon: null,
  href: '',
  onClick: null,
  children: null
};

DrawerListItem.propTypes = {
  icon: PropTypes.shape({}),
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.shape({})
};

export default DrawerListItem;
