import { Avatar, Box, Chip } from '@mui/material';
import { styled } from '@mui/system';
import { BP_MD, BP_XL } from '../../../../shared/constants';

export const ProfileRootContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down(BP_XL)]: {
    flexDirection: 'row'
  }
}));

export const ProfileHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up(BP_XL)]: {
    '& >:not(:last-child)': {
      marginBottom: theme.spacing(2)
    }
  },
  [theme.breakpoints.down(BP_XL)]: {
    flexDirection: 'row',
    marginRight: 'auto',
    '& >:not(:last-child)': {
      marginRight: theme.spacing(2)
    }
  }
}));

export const ProfileActionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down(BP_XL)]: {
    marginLeft: 'auto'
  },
  '& >:not(:last-child)': {
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down(BP_MD)]: {
      marginBottom: theme.spacing(0.25)
    }
  },
  [theme.breakpoints.up(BP_XL)]: {
    marginTop: theme.spacing(2)
  }
}));

export const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  [theme.breakpoints.down(BP_MD)]: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}));

export const ActionChip = styled(Chip)(({ theme }) => ({
  '.MuiChip-label': {
    margin: theme.spacing(0, 1)
  }
}));
