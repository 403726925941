import { Box, Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/system';

export const RoundedRepoButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  border: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.06)',
  borderRadius: theme.spacing(4),
  '&:hover': {
    border: 0,
    borderRadius: theme.spacing(4)
  }
}));

export const RoundedRepoLabelButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(1)
  }
}));

export const RoundedRepoLabelButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-start',
  borderStyle: 'hidden',
  borderRadius: theme.spacing(4),
  backgroundColor: 'transparent',
  '&:hover': {
    borderStyle: 'hidden',
    borderRadius: theme.spacing(4),
    backgroundColor: 'transparent',
    cursor: 'default'
  },
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'none'
}));

export const RoundedRepoSyncButton = styled(Button)(({ theme }) => ({
  width: 'auto',
  borderLeftColor: 'rgba(0, 0, 0, 0.12)',
  borderTopStyle: 'hidden',
  borderBottomStyle: 'hidden',
  borderRightStyle: 'hidden',
  borderRadius: theme.spacing(4),
  '&:hover': {
    borderLeftColor: 'rgba(0, 0, 0, 0.12)',
    borderTopStyle: 'hidden',
    borderBottomStyle: 'hidden',
    borderRightStyle: 'hidden',
    borderRadius: theme.spacing(4)
  },
  textTransform: 'none'
}));

export const RoundedRepoSyncButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(2),
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(0.5)
  }
}));

export const RoundedRepoRemoveButton = styled(Button)(({ theme }) => ({
  width: 'auto',
  borderLeftColor: 'rgba(0, 0, 0, 0.12)',
  borderTopStyle: 'hidden',
  borderBottomStyle: 'hidden',
  borderRightStyle: 'hidden',
  borderRadius: theme.spacing(4),
  '&:hover': {
    borderLeftColor: 'rgba(0, 0, 0, 0.12)',
    borderTopStyle: 'hidden',
    borderBottomStyle: 'hidden',
    borderRightStyle: 'hidden',
    borderRadius: theme.spacing(4)
  },
  textTransform: 'none'
}));

export const RoundedRepoRemoveButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(3),
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(0.5)
  }
}));
