import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { getFirstLetter, isNilOrEmpty } from '../../../shared/utils';

const RepoAvatar = ({ name, src, sx }) =>
  isNilOrEmpty(src) ? (
    <Avatar alt={name} sx={sx}>
      {getFirstLetter(name)}
    </Avatar>
  ) : (
    <Avatar alt={name} src={src} sx={sx}>
      {getFirstLetter(name)}
    </Avatar>
  );

RepoAvatar.defaultProps = {
  src: null,
  sx: {}
};

RepoAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string,
  sx: PropTypes.shape({})
};

export default RepoAvatar;
