import axios from 'axios';
import i18n from 'i18next';
import UserNotFound from '../../../../../server/errors/UserNotFound';
import { AXIOS_CONFIG } from '../../../../../shared/constants';
import ThunkError from '../../../../shared/errors/ThunkError';
import { set as setFeedback } from '../../../reducers/feedback';

const getUserGitLabRepo = (username) => async (dispatch) => {
  const instance = axios.create(AXIOS_CONFIG);

  try {
    await dispatch(setFeedback(null));
    const resp = await instance.get(`/api/u/${username}/log`);
    const { data } = resp;
    return data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      const { status, data: message } = e.response;
      if (status === 404) {
        throw new UserNotFound(message);
      }
      // re-throw the error
      throw new ThunkError(message);
    }
    throw new ThunkError(i18n.t('error.async.unableToGetUserRepos'));
  }
};

export default getUserGitLabRepo;
