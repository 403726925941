import MenuIcon from '@mui/icons-material/MenuOutlined';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { withAuth } from '../../hocs/withAuth';
import { FlexRowContainer } from '../../shared/Common.styles';
import RoundedButton from '../../shared/RoundedButton';
import Logo from './logo/Logo';
import { Toolbar as ToolbarStyled, ToolbarContainer, ToolbarText } from './Toolbar.styles';

const Toolbar = (props) => {
  const {
    auth: { username, isAuthenticated, hasSubscription },
    t,
    toggleDrawer
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();

  const onLogoTextClick = () => {
    navigate(isAuthenticated ? `/${username}` : t('path.root'));
  };

  const onUpgradeClick = () => {
    navigate(t('path.frontend.settings'));
  };

  const onSigninClick = () => {
    navigate(t('path.frontend.signin'));
  };

  const onSignupClick = () => {
    navigate(t('path.frontend.signup'));
  };

  return (
    <ToolbarStyled>
      <FlexRowContainer>
        <Logo auth={{ username, isAuthenticated }} />
        <ToolbarText onClick={onLogoTextClick} sx={{ '&:hover': { cursor: 'pointer' } }}>
          {t('logo.text')}
        </ToolbarText>
      </FlexRowContainer>
      {isAuthenticated ? (
        <ToolbarContainer>
          {!hasSubscription && (
            <RoundedButton
              text={t('settings.upgrade')}
              onClick={onUpgradeClick}
              sx={{ maxWidth: theme.spacing(10), padding: 0 }}
            />
          )}
          <MenuIcon fontSize="large" onClick={toggleDrawer(true)} />
        </ToolbarContainer>
      ) : (
        <ToolbarContainer>
          <RoundedButton
            text={t('auth.signin')}
            onClick={onSigninClick}
            sx={{ width: '10%', maxWidth: theme.spacing(10), padding: 0 }}
          />
          <RoundedButton
            text={t('auth.join')}
            onClick={onSignupClick}
            sx={{ width: '10%', maxWidth: theme.spacing(10), padding: 0 }}
          />
        </ToolbarContainer>
      )}
    </ToolbarStyled>
  );
};

Toolbar.defaultProps = {
  auth: {
    username: '',
    isAuthenticated: false,
    hasSubscription: false
  }
};

Toolbar.propTypes = {
  auth: PropTypes.shape({
    username: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    hasSubscription: PropTypes.bool
  }),
  t: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired
};

export default withAuth()(withTranslation()(Toolbar));
