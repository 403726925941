import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const RootContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(4, 0),
  '& >:not(:last-child)': {
    marginBottom: theme.spacing(2)
  }
}));

export const HeaderTypography = styled(Typography)(() => ({
  fontWeight: 300,
  opacity: 0.65,
  textAlign: 'center',
  lineHeight: 1.25
}));
