import { isEmpty, isNil } from 'ramda';
import { AUTH_ORIGIN_SIGNIN, AUTH_ORIGIN_SIGNUP, GITHUB, GITLAB, PRODUCTION } from './constants';

export const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

export const isProductionMode = () => process.env.NODE_ENV === PRODUCTION;

export const isAuthOriginSignin = (origin) => typeof origin === 'string' && origin === AUTH_ORIGIN_SIGNIN;

export const isAuthOriginSignup = (origin) => origin === AUTH_ORIGIN_SIGNUP;

export const isNilOrEmpty = (_) => isNil(_) || isEmpty(_);

export const getWrappedComponentDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

export const capitalize = (string) => {
  if (isNilOrEmpty(string)) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getUsernameFromEmail = (email) => email.slice(0, email.indexOf('@'));

export const isUsernameValid = (username) => {
  if (isNilOrEmpty(username)) {
    return false;
  }
  // can start with a-z, 0-9 or _
  // can end with a-z, 0-9 or _
  // can have a-z, 0-9, _ or . in the middle up to 13
  return /^[a-z0-9_][a-z0-9_.]{4,13}[a-z0-9_]$/.test(username);
};

export const extractUsername = (pathname) => {
  if (isNilOrEmpty(pathname)) {
    return null;
  }

  // lower case pathname
  const lcp = pathname.toLowerCase();

  // pathname starts with a / and can end with a /
  const regexp = /^\/([a-z0-9_][a-z0-9_.]{4,13}[a-z0-9_])\/?$/;

  if (!regexp.test(lcp)) {
    return null;
  }

  const match = lcp.match(regexp);
  if (match && match.length > 1 && match[1]) {
    return match[1];
  }

  return null;
};

const isLinked = (provider, oauths) => oauths.indexOf(provider) >= 0;

export const isGitLabLinked = (oauths) => isLinked(GITLAB, oauths);

export const isGitHubLinked = (oauths) => isLinked(GITHUB, oauths);

export const getFirstLetter = (s) => (isNilOrEmpty(s) ? null : s.toUpperCase().slice(0, 1));

export const getAxiosErrorStatus = ({ response: { status } }) => status;

export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
