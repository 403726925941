import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { isProductionMode } from '../../shared/utils';
import reducers from './reducers';

export default (initialState) =>
  configureStore({
    middleware: [thunk],
    reducer: reducers,
    preloadedState: initialState,
    devTools: !isProductionMode()
  });
