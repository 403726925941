import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getWrappedComponentDisplayName, isNilOrEmpty } from '../../../shared/utils';
import { auth as authSelector, data as dataSelector, progress as progressSelector } from '../../store/selectors';
import { isAuthProgressComplete } from '../../store/utils';
import ProgressSpinner from '../shared/ProgressSpinner';

const mapStateToProps = (state) => ({
  auth: dataSelector(authSelector(state)),
  progress: progressSelector(authSelector(state))
});

const withAuth = () => (WrappedComponent) => {
  const WithAuth = (props) => {
    const { auth, progress, ...rest } = props;

    const id = isNilOrEmpty(auth) ? null : auth.id;
    const username = isNilOrEmpty(auth) ? '' : auth.username;
    const isAuthenticated = !isNilOrEmpty(auth) && !isNilOrEmpty(auth.id);
    const hasSubscription = !isNilOrEmpty(auth) && auth.hasSubscription;
    const subscriptionCanceled = !isNilOrEmpty(auth) && auth.subscriptionCanceled;
    const subscriptionEndsAt = !isNilOrEmpty(auth) && !isNilOrEmpty(auth.subscriptionEndsAt) ? auth.subscriptionEndsAt : null;

    return isAuthProgressComplete(progress) ? (
      <WrappedComponent
        auth={{ id, username, isAuthenticated, hasSubscription, subscriptionCanceled, subscriptionEndsAt }}
        {...rest}
      />
    ) : (
      <ProgressSpinner open />
    );
  };

  WithAuth.propTypes = {
    auth: PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      hasSubscription: PropTypes.bool,
      subscriptionCanceled: PropTypes.bool,
      subscriptionEndsAt: PropTypes.number
    }).isRequired,
    progress: PropTypes.string.isRequired
  };

  WithAuth.displayName = `WithAuth(${getWrappedComponentDisplayName(WrappedComponent)})`;

  return connect(mapStateToProps, null)(WithAuth);
};

export { withAuth };
