import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { PageContainer, PageContentContainer } from '../shared/Common.styles';
import Link from '../shared/Link';
import { ErrorPageContentContainer, ErrorPageHeader } from './ErrorPage.styles';

const UnauthorizedPage = ({ t, message }) => (
  <PageContainer sx={{ marginTop: '15vh', marginBottom: '20vh' }}>
    <PageContentContainer>
      <ErrorPageHeader variant="h5" gutterBottom sx={{ color: 'rgb(0, 0, 0, 0.5)' }}>
        {t('error.auth.unauthorizedAccess')}
      </ErrorPageHeader>
      <ErrorPageContentContainer>
        <Link
          href={t('path.frontend.signin')}
          text={t('auth.signin')}
          gutterBottom
          variant="body1"
          sx={{ fontWeight: '600', color: 'rgb(193, 25, 21, 0.75)' }}
        />
        <Typography variant="body1" gutterBottom sx={{ color: 'rgb(0, 0, 0, 0.5)' }}>
          {message}
        </Typography>
      </ErrorPageContentContainer>
    </PageContentContainer>
  </PageContainer>
);

UnauthorizedPage.propTypes = {
  t: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export default withTranslation()(UnauthorizedPage);
