import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ProviderListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(2)
  },
  margin: theme.spacing(4, 0)
}));
