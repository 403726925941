import { ALERT_SEVERITY_ERROR, PROGRESS_AUTH_USER_COMPLETE } from '../../shared/constants';
import { isNilOrEmpty } from '../../shared/utils';
import { progress as setAuthProgress } from './reducers/auth';

export const completeAuthProgress = async (dispatch) => {
  dispatch(setAuthProgress(PROGRESS_AUTH_USER_COMPLETE));
};

export const isAuthProgressComplete = (progress) => !isNilOrEmpty(progress) && progress === PROGRESS_AUTH_USER_COMPLETE;

export const getErrorFeedback = (e, message, data = { status: 500 }) => {
  // attempt to parse server error first
  if (!isNilOrEmpty(e) && !isNilOrEmpty(e.response)) {
    const {
      response: { data: errorMessage, status: errorStatus }
    } = e;

    return {
      message: errorMessage,
      type: ALERT_SEVERITY_ERROR,
      data: {
        status: errorStatus
      }
    };
  }

  // fallback to the given default error message
  return {
    message,
    type: ALERT_SEVERITY_ERROR,
    data
  };
};
