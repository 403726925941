import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FlexColumnContainer } from '../../shared/Common.styles';
import RoundedButton from '../../shared/RoundedButton';
import { ProviderButtonIcon } from './ProviderButton.styles';

const ProviderButton = ({ text, iconSrc, iconAlt, onClick }) => (
  <FlexColumnContainer sx={{ justifyContent: 'center', alignItems: 'center' }}>
    <ProviderButtonIcon variant="square" src={iconSrc} alt={iconAlt} />
    <Typography noWrap gutterBottom variant="overline" component="p">
      {iconAlt}
    </Typography>
    <RoundedButton type="body2" text={text} size="medium" sx={{ padding: 0 }} onClick={onClick} />
  </FlexColumnContainer>
);

ProviderButton.defaultProps = {
  iconSrc: null,
  iconAlt: null,
  onClick: () => {}
};

ProviderButton.propTypes = {
  text: PropTypes.string.isRequired,
  iconSrc: PropTypes.string,
  iconAlt: PropTypes.string,
  onClick: PropTypes.func
};

export default ProviderButton;
