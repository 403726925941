import { TextField } from '@mui/material';
import { styled } from '@mui/system';

export const RoundedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    borderRadius: theme.spacing(2),
    textDecoration: 'none',
    padding: theme.spacing(1, 2)
  },
  '& .MuiFilledInput-input': {
    padding: 0
  },
  '& .MuiFilledInput-root:hover:not(.Mui-disabled):after': {
    border: 0
  },
  '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
    border: 0
  },
  '& .MuiFilledInput-root:after': {
    border: 0
  },
  '& .MuiFilledInput-root:before': {
    border: 0
  },
  '& .MuiFormHelperText-root': {
    color: 'rgb(193, 25, 21, 0.75)'
  },
  // removing blue-ish input background colour of Chrome autocomplete
  '& input:-webkit-autofill': {
    borderRadius: 0,
    WebkitBackgroundClip: 'text'
  }
}));
