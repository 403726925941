import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const GridRow = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly'
}));

export const GridColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  padding: theme.spacing(2, 0)
}));
