import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Drawer, List } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MENU_ANCHOR_RIGHT, MENU_ANCHOR_TOP } from '../../../../shared/constants';
import { useScreenSizeDetector } from '../../hooks/useScreenSizeDetector';
import DrawerListDivider from './DrawerListDivider';
import DrawerListItem from './DrawerListItem';

const MenuDrawer = ({ t, drawer: open, toggleDrawer }) => {
  const navigate = useNavigate();
  const closeMenuDrawer = toggleDrawer(false);
  const isSmallScreen = useScreenSizeDetector();

  const onSettingsClick = () => {
    closeMenuDrawer();
    navigate(t('path.frontend.settings'));
  };

  return (
    <Drawer anchor={isSmallScreen ? MENU_ANCHOR_TOP : MENU_ANCHOR_RIGHT} open={open} onClose={closeMenuDrawer}>
      <List>
        <DrawerListItem icon={<SettingsRoundedIcon />} text={t('menu.settings')} onClick={onSettingsClick} />
        <DrawerListDivider />
        <DrawerListItem icon={<LogoutRoundedIcon />} text={t('menu.signout')} href={t('path.backend.auth.signout')} />
      </List>
    </Drawer>
  );
};

MenuDrawer.propTypes = {
  t: PropTypes.func.isRequired,
  drawer: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired
};

export default withTranslation()(MenuDrawer);
