import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { BP_MD, BP_MOBILE_PORTRAIT } from '../../../shared/constants';
import { isNilOrEmpty } from '../../../shared/utils';
import { withAuth } from '../hocs/withAuth';
import { withProgress } from '../hocs/withProgress';
import { useScreenSizeDetector } from '../hooks/useScreenSizeDetector';
import { useThunk } from '../hooks/useThunk';
import { FlexColumnContainer, PageContainer } from '../shared/Common.styles';
import Link from '../shared/Link';
import RoundedButton from '../shared/RoundedButton';
import ContributionCalendar from '../user/contribution-calendar/ContributionCalendar';
import ContributionDialog from '../user/contribution-dialog/ContributionDialog';
import { getPreferredThemeIndex, THEMES } from '../user/contribution-legend/ContributionLegendList';
import ProfileRepoFilter from '../user/profile-repo-filter/ProfileRepoFilter';
import RepoHeader from '../user/repo-header/RepoHeader';
import { ContributionCalendarContainer } from '../user/UserPage.styles';
import {
  ContributionCalendarRootContainer,
  Divider,
  DividerContainer,
  FeatureSubheaderTypography,
  HeaderTypography,
  RepoProviderContainer,
  SubheaderTypography
} from './LandingPage.styles';
import PricingTable from './pricing-table/PricingTable';
import RepoProvider from './repo-provider/RepoProvider';

// dayjs plugins
dayjs.extend(timezone);
dayjs.extend(utc);

const username = 'zzalkiro';

const LandingPage = ({ t, auth, getUserRepo, setProgress }) => {
  const [state, setState] = useState({
    data: [],
    index: 0,
    contributionDialog: { open: false }
  });
  const [selectedTheme, setSelectedTheme] = useState(THEMES[getPreferredThemeIndex()]);
  const isUnderMobileSize = useScreenSizeDetector(BP_MOBILE_PORTRAIT);
  const isUnderTabletSize = useScreenSizeDetector(BP_MD);
  const navigate = useNavigate();
  const theme = useTheme();

  const getParams = useCallback(
    () => ({
      thunk: getUserRepo,
      params: username,
      setProgress,
      options: {
        onSuccess: (data) => {
          const { repos } = data;
          if (repos && repos.length > 1) {
            repos.unshift({
              name: t('userPage.all'),
              pathWithNamespace: t('userPage.all'),
              firstActivityAt: dayjs().utc().unix()
            });
          }
          if (repos && repos.length > 0) {
            // set the parsed logs in the state
            setState((pstate) => ({
              ...pstate,
              data: repos,
              error: null
            }));
          } else {
            setState((pstate) => ({
              ...pstate,
              data: [],
              error: null
            }));
          }
        },
        onFailure: (e) => {
          setState((pstate) => ({
            ...pstate,
            error: e.message
          }));
        }
      }
    }),
    [t, getUserRepo, setProgress]
  );

  // Use useCallback to keep the function reference identical during render cycle to ensure
  // it's executed only once per render & null thunk is passwed to ignore when not authenticated
  const isThunkCalled = useThunk(getParams);

  const onFilterClick = (index) => () => {
    setState((pstate) => ({
      ...pstate,
      index
    }));
  };

  const changeTheme = (newSelectedTheme) => {
    setSelectedTheme(newSelectedTheme);
  };

  const openContributionDialog = (title, commits) => {
    setState((pstate) => ({ ...pstate, contributionDialog: { open: true, title, commits } }));
  };

  const closeContributionDialog = () => {
    setState((pstate) => ({ ...pstate, contributionDialog: { ...pstate.contributionDialog, open: false } }));
  };

  const onCheckoutExampleClick = () => {
    navigate(`/${username}`);
  };

  const onSignupClick = () => {
    navigate(t('path.frontend.signup'));
  };

  return (
    <PageContainer>
      {auth.isAuthenticated && !isNilOrEmpty(auth.username) && <Navigate to={`/${auth.username}`} replace />}
      <ContributionDialog
        onClose={closeContributionDialog}
        open={state.contributionDialog.open}
        title={state.contributionDialog.title}
        commits={state.contributionDialog.commits}
      />
      <FlexColumnContainer sx={{ alignItems: 'center', padding: theme.spacing(0, 2) }}>
        {/* header & subheader */}
        <HeaderTypography variant={isUnderTabletSize ? 'h4' : 'h3'}>{t('landing.header')}</HeaderTypography>
        <SubheaderTypography variant={isUnderTabletSize ? 'h6' : 'h5'}>{t('landing.subheader')}</SubheaderTypography>
        {/* contribution calendar example */}
        {isThunkCalled && !isNilOrEmpty(state.data) && (
          <FlexColumnContainer sx={{ alignItems: 'center' }}>
            <ContributionCalendarRootContainer>
              {state.data.length > 1 && (
                <ProfileRepoFilter data={state.data} index={state.index} onFilterClick={onFilterClick} />
              )}
              {((state.data.length > 1 && state.index > 0) || state.data.length === 1) && (
                <RepoHeader {...state.data[state.index]} selfAuthorized={false} />
              )}
              <ContributionCalendarContainer>
                <ContributionCalendar
                  data={state.data}
                  index={state.index}
                  theme={selectedTheme}
                  changeTheme={changeTheme}
                  isUnderMobileSize={isUnderMobileSize}
                  isUnderTabletSize={isUnderTabletSize}
                  openContributionDialog={openContributionDialog}
                />
              </ContributionCalendarContainer>
            </ContributionCalendarRootContainer>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <Link
              variant="body1"
              onClick={onCheckoutExampleClick}
              text={t('landing.checkout')}
              sx={{
                marginTop: theme.spacing(4),
                lineHeight: 'normal',
                textAlign: 'center'
              }}
              underline
            />
            {/* eslint-enable jsx-a11y/anchor-is-valid */}
          </FlexColumnContainer>
        )}
        {/* manage repo button */}
        <RoundedButton
          text={t('landing.manageRepo')}
          onClick={onSignupClick}
          sx={{
            margin: theme.spacing(2, 0),
            maxWidth: theme.spacing(32),
            marginTop: theme.spacing(8),
            [theme.breakpoints.down(BP_MD)]: {
              maxWidth: theme.spacing(30),
              marginTop: theme.spacing(8)
            },
            [theme.breakpoints.down(BP_MD)]: {
              marginTop: theme.spacing(6)
            }
          }}
        />
        {/* divider */}
        <DividerContainer>
          <Divider />
        </DividerContainer>
        {/* multi repository feature */}
        <FeatureSubheaderTypography variant={isUnderTabletSize ? 'h6' : 'h5'}>
          {t('landing.multipleRepo')}
        </FeatureSubheaderTypography>
        {/* repo providers */}
        <RepoProviderContainer>
          <RepoProvider src={t('auth.gitlabOAuth.iconSrc120')} alt={t('auth.gitlabOAuth.iconAlt')} />
          <RepoProvider src={t('auth.githubOAuth.iconSrc120')} alt={t('auth.githubOAuth.iconAlt')} />
        </RepoProviderContainer>
        {/* divider  */}
        <DividerContainer>
          <Divider />
        </DividerContainer>
        {/* pricing */}
        <FeatureSubheaderTypography variant={isUnderTabletSize ? 'h6' : 'h5'}>
          {t('landing.pricing')}
        </FeatureSubheaderTypography>
        <PricingTable
          sx={{
            maxWidth: theme.spacing(45),
            [theme.breakpoints.down(BP_MD)]: {
              maxWidth: theme.spacing(42)
            }
          }}
        />
        <RoundedButton
          text={t('landing.getStarted')}
          onClick={onSignupClick}
          sx={{
            margin: theme.spacing(2, 0),
            maxWidth: theme.spacing(32),
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(20),
            [theme.breakpoints.down(BP_MD)]: {
              maxWidth: theme.spacing(30),
              marginTop: theme.spacing(8),
              marginBottom: theme.spacing(18)
            },
            [theme.breakpoints.down(BP_MD)]: {
              marginTop: theme.spacing(6),
              marginBottom: theme.spacing(16)
            }
          }}
        />
      </FlexColumnContainer>
    </PageContainer>
  );
};

LandingPage.defaultProps = {
  auth: PropTypes.shape({
    username: null,
    isAuthenticated: false
  })
};

LandingPage.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    username: PropTypes.string,
    isAuthenticated: PropTypes.bool
  }),
  getUserRepo: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired
};

export default withProgress()(withTranslation()(withAuth()(LandingPage)));
