import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import Cookies from 'js-cookie';
import { once } from 'ramda';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import '../i18n';
import { COOKIE_KEY_NONCE } from '../shared/constants';
import AppRouter from './components/routers/AppRouter';
import createStore from './store';
import initializeApp from './store/initializers/initializeApp';
import './styles/styles.scss';
import { theme } from './themes/AppThemes';

// retrieve nonce set by server
const nonce = Cookies.get(COOKIE_KEY_NONCE);

// fallback meta tag nonce
const cacheOptions = {
  key: 'reporay',
  nonce: nonce || document.querySelector('meta[property="csp-nonce"]').content,
  prepend: true
};

// create @emotion cache with server generated nonce stored in cookie
const cache = createCache(cacheOptions);

// remove nonce from coockie
Cookies.remove(COOKIE_KEY_NONCE);

const initialState = {};
const initializeAppOnce = once(initializeApp);
const store = createStore(initialState);
initializeAppOnce(initialState, store);

const App = () => (
  <Provider store={store}>
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </CacheProvider>
  </Provider>
);

const app = ReactDOM.createRoot(document.getElementById('app'));

app.render(<App />);
