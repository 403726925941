import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const ErrorPageContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(1)
  },
  marginBottom: theme.spacing(2)
}));

export const ErrorPageHeader = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  marginBottom: theme.spacing(3)
}));
