import { Typography } from '@mui/material';
import React from 'react';

const VerticalDivider = () => (
  <Typography variant="caption" component="span">
    |
  </Typography>
);

export default VerticalDivider;
