import { IconButton as MuiIconButton } from '@mui/material';
import { styled } from '@mui/system';

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  padding: 0,
  opacity: 0.7,
  '&:hover': {
    opacity: 1
  }
}));
