import axios from 'axios';
import i18n from 'i18next';
import { AXIOS_CONFIG } from '../../../../../shared/constants';
import ThunkError from '../../../../shared/errors/ThunkError';
import { set as setFeedback } from '../../../reducers/feedback';

const patchUserRepoLogs = (uid, rid, provider, repoId, name) => async (dispatch) => {
  const instance = axios.create(AXIOS_CONFIG);
  try {
    await dispatch(setFeedback(null));
    await instance.patch(`/api/u/${uid}/repo/${rid}`, {
      data: {
        provider,
        repoId,
        reponame: name
      }
    });
  } catch (e) {
    if (e && e.response && e.response.data) {
      const { status, data: message } = e.response;
      if (status === 401 || status === 403) {
        throw new ThunkError(i18n.t('notification.oauth.sessionExpired', { provider: i18n.t(`common.${provider}`) }));
      }
      if (status === 404) {
        throw new ThunkError(
          i18n.t('notification.settings.projectUnavailable', { name, provider: i18n.t(`common.${provider}`) })
        );
      }
      // re-throw the error
      throw new ThunkError(message);
    }
    throw new ThunkError(i18n.t('error.async.unableToSyncUserRepoLogs'));
  }
};

export default patchUserRepoLogs;
