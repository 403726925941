export const LOWER_ALPHABET = 'abcdefghijklmnopqrstuvwxyz';
export const EMAIL_ADDRESS = 'reporay@nestedtree.com';
export const PRODUCTION = 'production';
export const YES = 'yes';
export const TMP_PATH_PREFIX = 'reporay';

export const AXIOS_CONFIG = {
  timeout: 30000 // 30 seconds
};

export const GITHUB_APP_ID = parseInt(process.env.GITHUB_APP_ID, 10);

// Subscription failure types
export const SUBSCRIPTION_SUCCESS_CHECKOUT = 'SUBSCRIPTION_SUCCESS_CHECKOUT';
export const SUBSCRIPTION_CANCELED_CHECKOUT = 'SUBSCRIPTION_CANCELED_CHECKOUT';
export const SUBSCRIPTION_FAILURE_CHECKOUT = 'SUBSCRIPTION_FAILURE_CHECKOUT';
export const SUBSCRIPTION_FAILURE_NO_SESSION_ID = 'SUBSCRIPTION_FAILURE_NO_SESSION_ID';
export const SUBSCRIPTION_FAILURE_ERROR_IN_SUCCESS = 'SUBSCRIPTION_FAILURE_ERROR_IN_SUCCESS';
export const SUBSCRIPTION_FAILURE_NO_SUBSCRIPTION_DATA = 'SUBSCRIPTION_FAILURE_NO_SUBSCRIPTION_DATA';
export const SUBSCRIPTION_FAILURE_BILLING_PORTAL = 'SUBSCRIPTION_FAILURE_BILLING_PORTAL';

// Repo Visibility
export const VISIBILITY_PUBLIC = 'public';
export const VISIBILITY_PRIVATE = 'private';
export const VISIBILITY_INTERNAL = 'internal';

// Reserved username
export const RESERVED_USERNAMES = [
  'signin',
  'signup',
  'settings',
  'password',
  'reset',
  'profile',
  'privacy',
  'policy',
  'privacy_policy',
  'privacy-policy',
  'tnc',
  'terms',
  'and',
  'conditions',
  'terms_and_conditions',
  'terms-and-conditions',
  'seulkiro',
  'reporay'
];

// App Theme Screen Breakpoints
export const BP_XS = 'xs';
export const BP_SM = 'sm';
export const BP_MD = 'md';
export const BP_LG = 'lg';
export const BP_XL = 'xl';
export const BP_MOBILE_PORTRAIT = 'mobilePortrait';
export const BP_MOBILE_LANDSCAPE = 'mobileLandscape';
export const BP_TABLET = 'tablet';

// Menu Anchor Position
export const MENU_ANCHOR_TOP = 'top';
export const MENU_ANCHOR_RIGHT = 'right';

// Alert Severity
export const ALERT_SEVERITY_NONE = '';
export const ALERT_SEVERITY_ERROR = 'error';
export const ALERT_SEVERITY_WARNING = 'warning';
export const ALERT_SEVERITY_INFO = 'info';
export const ALERT_SEVERITY_SUCCESS = 'success';

// Auth Progress Levels
export const PROGRESS_AUTH_USER_COMPLETE = 'PROGRESS_AUTH_USER_COMPLETE';
export const PROGRESS_AUTH_USER_IN_PROGRESS = 'PROGRESS_AUTH_USER_IN_PROGRESS';

// OAuth Types
export const GITLAB = 'gitlab';
export const GITHUB = 'github';
export const LOCAL = 'local';

// OAuth Failure Types
export const OAUTH_SUCCESS = 'OAUTH_SUCCESS';
export const OAUTH_REVOKED = 'OAUTH_REVOKED';
export const OAUTH_ACCESS_DENIED = 'OAUTH_ACCESS_DENIED';
export const OAUTH_FAILURE_NOT_AUTHENTICATED = 'OAUTH_FAILURE_NOT_AUTHENTICATED';
export const OAUTH_FAILURE_UNABLE_TO_REVOKE = 'OAUTH_FAILURE_UNABLE_TO_REVOKE';
export const OAUTH_FAILURE_ALREADY_REGISTERED = 'OAUTH_FAILURE_ALREADY_REGISTERED ';

// Auth Origin
export const AUTH_ORIGIN_SIGNIN = 'signin';
export const AUTH_ORIGIN_SIGNUP = 'signup';

// Auth Failure Types
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_FAILURE_NO_EMAIL_PROVIDED = 'AUTH_FAILURE_NO_EMAIL_PROVIDED';
export const AUTH_FAILURE_EMAIL_ALREADY_IN_USE = 'AUTH_FAILURE_EMAIL_ALREADY_IN_USE';
export const AUTH_FAILURE_OAUTH_ALREADY_IN_USE = 'AUTH_FAILURE_OAUTH_ALREADY_IN_USE';
export const AUTH_FAILURE_INVALID_ORIGIN = 'AUTH_FAILURE_INVALID_ORIGIN';
export const AUTH_FAILURE_NO_ACCOUNT_FOUND = 'AUTH_FAILURE_NO_ACCOUNT_FOUND';
export const AUTH_FAILURE_INCORRECT_PASSWORD = 'AUTH_FAILURE_INCORRECT_PASSWORD';

// Cookie Keys
export const COOKIE_KEY_NONCE = 'nonce';
export const COOKIE_KEY_AUTH_FAILURE = 'authFailure';
export const COOKIE_KEY_OAUTH_RESPONSE = 'oauthResponse';
export const COOKIE_KEY_SUBSCRIPTION_RESPONSE = 'subscriptionResponse';
export const COOKIE_KEY_REMEMBER_ME = 'remember_me';
export const COOKIE_KEY_PREFERRED_THEME = 'preferred_theme';

export const CONTRIBUTION_THEME_DEFAULT = 'default';
export const CONTRIBUTION_THEME_GITLAB = 'gitlab';
export const CONTRIBUTION_THEME_GITHUB = 'github';
export const CONTRIBUTION_THEME_THANKSGIVING = 'thanksgiving';
export const CONTRIBUTION_THEME_XMAS_RED = 'xmasr';
export const CONTRIBUTION_THEME_XMAS_GREEN = 'xmasg';

// Route Methods
export const DELETE = 'DELETE';
export const GET = 'GET';
export const PATCH = 'PATCH';
export const POST = 'POST';
