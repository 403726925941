import { Box, Divider as MuiDivider, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { BP_MD, BP_SM } from '../../../shared/constants';

export const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  fontFamily: 'Cabin',
  textAlign: 'center',
  maxWidth: theme.spacing(65),
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down(BP_MD)]: {
    maxWidth: theme.spacing(42),
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down(BP_SM)]: {
    maxWidth: theme.spacing(42),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    lineHeight: 'normal'
  }
}));

export const SubheaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  textAlign: 'center',
  maxWidth: theme.spacing(75),
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down(BP_MD)]: {
    maxWidth: theme.spacing(62),
    marginBottom: theme.spacing(6)
  },
  [theme.breakpoints.down(BP_SM)]: {
    maxWidth: theme.spacing(42),
    lineHeight: 'normal'
  }
}));

export const FeatureSubheaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  fontFamily: 'Cabin',
  textAlign: 'center',
  maxWidth: theme.spacing(62),
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down(BP_MD)]: {
    marginBottom: theme.spacing(6)
  },
  [theme.breakpoints.down(BP_SM)]: {
    maxWidth: theme.spacing(40),
    lineHeight: 'normal'
  }
}));

export const ContributionCalendarRootContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& >:not(:last-child)': {
    marginBottom: theme.spacing(2)
  }
}));

export const DividerContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(10, 0),
  [theme.breakpoints.down(BP_MD)]: {
    margin: theme.spacing(8, 0)
  },
  [theme.breakpoints.down(BP_SM)]: {
    margin: theme.spacing(6, 0)
  }
}));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  opacity: '0.75',
  fontSize: 'small',
  width: '100%',
  margin: theme.spacing(2, 0),
  maxWidth: theme.spacing(40),
  [theme.breakpoints.down(BP_MD)]: {
    margin: theme.spacing(1, 0)
  }
}));

export const RepoProviderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(4)
  }
}));
