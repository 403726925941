import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Link from '../../shared/Link';
import { LinkList as LinkListStyled } from './LinkList.styles';
import VerticalDivider from './VerticalDivider';

/* eslint-disable jsx-a11y/anchor-is-valid */
const LinkList = ({ t }) => {
  const navigate = useNavigate();

  const onTermsAndConditionsClicked = () => {
    navigate(t('path.frontend.termsAndConditions'));
  };

  const onPrivacyPolicyClicked = () => {
    navigate(t('path.frontend.privacyPolicy'));
  };

  return (
    <LinkListStyled>
      <Link onClick={onTermsAndConditionsClicked} text={t('footer.termsAndConditions')} />
      <VerticalDivider />
      <Link onClick={onPrivacyPolicyClicked} text={t('footer.privacyPolicy')} />
    </LinkListStyled>
  );
};

LinkList.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(LinkList);
