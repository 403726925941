import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { BP_MD } from '../../../../shared/constants';
import { getFirstLetter, isNilOrEmpty } from '../../../../shared/utils';
import { useScreenSizeDetector } from '../../hooks/useScreenSizeDetector';
import { FlexColumnContainer } from '../../shared/Common.styles';
import {
  ActionChip,
  ProfileActionContainer,
  ProfileAvatar,
  ProfileHeaderContainer,
  ProfileRootContainer
} from './ProfileHeader.styles';

const ProfileHeader = (props) => {
  const { t, selfAuthorized, limitAddRepo, name, username, avatarUrl, onEditProfileClick, onAddRepoClick } = props;
  const isUnderMD = useScreenSizeDetector(BP_MD);

  const getProfileAvatar = (alt, src) =>
    isNilOrEmpty(src) ? (
      <ProfileAvatar alt={alt}>{getFirstLetter(alt)}</ProfileAvatar>
    ) : (
      <ProfileAvatar alt={alt} src={src}>
        {getFirstLetter(alt)}
      </ProfileAvatar>
    );

  const getProfileActionButtion = (text, onClick, disabled) => (
    <ActionChip variant="outlined" size={isUnderMD ? 'small' : 'medium'} label={text} onClick={onClick} disabled={disabled} />
  );

  return (
    <ProfileRootContainer>
      <ProfileHeaderContainer>
        {getProfileAvatar(username, avatarUrl)}
        <FlexColumnContainer>
          <Typography noWrap variant={isUnderMD ? 'body1' : 'h6'} component="span" sx={{ padding: 0, lineHeight: 'unset' }}>
            {name}
          </Typography>
          <Typography
            noWrap
            variant={isUnderMD ? 'caption' : 'body1'}
            component="span"
            sx={{ padding: 0, lineHeight: 'unset', opacity: '0.6' }}
          >
            {`@${username}`}
          </Typography>
        </FlexColumnContainer>
      </ProfileHeaderContainer>
      <ProfileActionContainer>
        {selfAuthorized ? getProfileActionButtion(t('userPage.editSettings'), onEditProfileClick, false) : null}
        {selfAuthorized ? getProfileActionButtion(t('userPage.addRepo'), onAddRepoClick, limitAddRepo) : null}
      </ProfileActionContainer>
    </ProfileRootContainer>
  );
};

ProfileHeader.defaultProps = {
  selfAuthorized: false,
  name: null,
  avatarUrl: null
};

ProfileHeader.propTypes = {
  t: PropTypes.func.isRequired,
  selfAuthorized: PropTypes.bool,
  limitAddRepo: PropTypes.bool.isRequired,
  name: PropTypes.string,
  username: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  onEditProfileClick: PropTypes.func.isRequired,
  onAddRepoClick: PropTypes.func.isRequired
};

export default withTranslation()(ProfileHeader);
