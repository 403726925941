import { ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import RepoAvatar from '../../shared/RepoAvatar';

const RepoItem = ({
  id,
  onClick,
  name,
  pathWithNamespace,
  username,
  description,
  visibility,
  webUrl,
  avatarUrl,
  lastActivityAt,
  provider
}) => (
  <ListItem sx={{ padding: 0, margin: 0 }}>
    <ListItemButton
      onClick={() => {
        onClick({
          rid: id,
          name,
          pathWithNamespace,
          username,
          description,
          visibility,
          webUrl,
          avatarUrl,
          lastActivityAt,
          provider
        });
      }}
    >
      <ListItemAvatar>
        <RepoAvatar name={name} src={avatarUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={pathWithNamespace}
        secondary={description}
        secondaryTypographyProps={{
          noWrap: true,
          sx: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }
        }}
      />
    </ListItemButton>
  </ListItem>
);

RepoItem.defaultProps = {
  description: '',
  webUrl: '',
  avatarUrl: '',
  lastActivityAt: null
};

RepoItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  pathWithNamespace: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  description: PropTypes.string,
  visibility: PropTypes.string.isRequired,
  webUrl: PropTypes.string,
  avatarUrl: PropTypes.string,
  lastActivityAt: PropTypes.number,
  provider: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default RepoItem;
