import axios from 'axios';
import { AXIOS_CONFIG } from '../../../../shared/constants';
import { isNilOrEmpty } from '../../../../shared/utils';
import { set as setAuth } from '../../reducers/auth';

const getAuthUser = () => async (dispatch) => {
  const actions = [];
  try {
    const resp = await axios.get('/api/auth_user', AXIOS_CONFIG);
    const { data: user } = resp;
    actions.push(dispatch(setAuth(isNilOrEmpty(user) ? {} : user)));
  } catch (e) {
    actions.push(dispatch(setAuth({})));
  }
  Promise.all(actions).catch(() => {
    // TODO: set app error in case of fatal failures
  });
};

export default getAuthUser;
