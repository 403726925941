import React, { useState } from 'react';
import MenuDrawer from '../menu-drawer/MenuDrawer';
import Toolbar from '../toolbar/Toolbar';
import ToolbarOffset from '../toolbar/ToolbarOffset';
import { Appbar as AppbarStyled } from './Appbar.styles';

const Appbar = () => {
  const [state, setState] = useState({
    drawer: false
  });

  const toggleDrawer = (open) => () => {
    setState({ ...state, drawer: open });
  };

  return (
    <>
      <AppbarStyled color="default">
        <Toolbar toggleDrawer={toggleDrawer} />
      </AppbarStyled>
      <ToolbarOffset />
      <MenuDrawer drawer={state.drawer} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default Appbar;
