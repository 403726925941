import { Box, Chip } from '@mui/material';
import { styled } from '@mui/system';
import { BP_SM } from '../../../../shared/constants';

export const ProfileRepoFilterRootContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignContent: 'baseline',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down(BP_SM)]: {
      marginRight: theme.spacing(0.5)
    }
  }
}));

export const ProfileRepoFilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.125, 0)
}));
