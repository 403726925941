import { createTheme } from '@mui/material/styles';
import { BP_LG, BP_MD, BP_MOBILE_LANDSCAPE, BP_MOBILE_PORTRAIT, BP_SM, BP_TABLET, BP_XL, BP_XS } from '../../shared/constants';

const theme = createTheme({
  typography: {},
  palette: {
    type: 'light',
    primary: {
      main: '#4b3c48'
    },
    secondary: {
      main: '#3a3b3c'
    },
    background: {
      default: '#f5f7fa'
    },
    offwhite: {
      main: '#f7f7f7'
    },
    plum: {
      main: '#9f919d'
    }
  },
  breakpoints: {
    values: {
      [BP_XS]: 0,
      [BP_SM]: 600,
      [BP_MD]: 900,
      [BP_LG]: 1200,
      [BP_XL]: 1536,
      [BP_MOBILE_PORTRAIT]: 478,
      [BP_MOBILE_LANDSCAPE]: 767,
      [BP_TABLET]: 991
    }
  }
});

const parseRem = (rem) => parseFloat(rem.slice(0, -3));

const scaleDown = (val, pct) => val * pct;

const toRem = (val) => `${val}rem`;

const {
  typography: { h1, h2, h3, h4, h5, h6, subtitle1, subtitle2, body1, body2, button, caption, overline }
} = theme;

const getMobileFontSize = ({ fontSize }, pct) => ({
  fontSize,
  [theme.breakpoints.down(BP_MOBILE_LANDSCAPE)]: {
    fontSize: toRem(scaleDown(parseRem(fontSize), pct))
  }
});

const p = 0.9;
theme.typography.h1 = { ...h1, ...getMobileFontSize(h1, p) };
theme.typography.h2 = { ...h2, ...getMobileFontSize(h2, p) };
theme.typography.h3 = { ...h3, ...getMobileFontSize(h3, p) };
theme.typography.h4 = { ...h4, ...getMobileFontSize(h4, p) };
theme.typography.h5 = { ...h5, ...getMobileFontSize(h5, p) };
theme.typography.h6 = { ...h6, ...getMobileFontSize(h6, p) };
theme.typography.subtitle1 = { ...subtitle1, ...getMobileFontSize(subtitle1, p) };
theme.typography.subtitle2 = { ...subtitle2, ...getMobileFontSize(subtitle2, p) };
theme.typography.body1 = { ...body1, ...getMobileFontSize(body1, p) };
theme.typography.body2 = { ...body2, ...getMobileFontSize(body2, p) };
theme.typography.button = { ...button, ...getMobileFontSize(button, p) };
theme.typography.caption = { ...caption, ...getMobileFontSize(caption, p) };
theme.typography.overline = { ...overline, ...getMobileFontSize(overline, p) };

export { theme };
