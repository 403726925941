import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_WARNING,
  AUTH_FAILURE_EMAIL_ALREADY_IN_USE,
  AUTH_FAILURE_INCORRECT_PASSWORD,
  AUTH_FAILURE_NO_ACCOUNT_FOUND,
  COOKIE_KEY_AUTH_FAILURE
} from '../../../shared/constants';
import { isNilOrEmpty } from '../../../shared/utils';

const getAuthFailureResponse = () => {
  let resp;
  try {
    resp = JSON.parse(Cookies.get(COOKIE_KEY_AUTH_FAILURE) || '{}');
  } catch (e) {
    resp = {};
  } finally {
    Cookies.remove(COOKIE_KEY_AUTH_FAILURE);
  }
  return resp;
};

export const parseAuthFailureResponse = ({ type, email }, t) => {
  if (type === AUTH_FAILURE_INCORRECT_PASSWORD) {
    return {
      severity: ALERT_SEVERITY_ERROR,
      message: t('notification.authFailure.incorrectPassword')
    };
  }

  if (type === AUTH_FAILURE_EMAIL_ALREADY_IN_USE && !isNilOrEmpty(email)) {
    return {
      severity: ALERT_SEVERITY_WARNING,
      message: isNilOrEmpty(email)
        ? t('notification.authFailure.emailAlreadyInUse')
        : t('notification.authFailure.emailAlreadyInUseWithEmail', { email })
    };
  }

  if (type === AUTH_FAILURE_NO_ACCOUNT_FOUND) {
    return {
      severity: ALERT_SEVERITY_WARNING,
      message: isNilOrEmpty(email)
        ? t('notification.authFailure.noAccountFound')
        : t('notification.authFailure.noAccountFoundWithEmail', { email })
    };
  }

  return {
    message: t('notification.somethingWentWrong'),
    severity: ALERT_SEVERITY_ERROR
  };
};

export const useAuthFailureResponse = (handler) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      return;
    }
    setChecked(true);

    const resp = getAuthFailureResponse();
    if (resp) {
      handler(resp);
    }
  }, [checked, handler]);
};
