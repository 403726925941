import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { GET, POST } from '../../../../shared/constants';
import PricingTable from '../../landing-page/pricing-table/PricingTable';
import RoundedButton from '../../shared/RoundedButton';
import { HeaderTypography, RootContainerBox } from './Subscription.styles';

// TODO:
// - email & test subscription scenarios
//   - free -> paid
//   - paid -> cancel, needs to ack the remaining date
//   - cancel -> paid
// - verify emails based on the scenarios
// - password reset

// dayjs plugins
dayjs.extend(localizedFormat);

const Subscription = ({ t, uid, hasSubscription, subscriptionCanceled, subscriptionEndsAt }) => {
  const theme = useTheme();

  const getHeaderText = () => {
    if (!hasSubscription) {
      return t('settings.pricing.header.single');
    }
    if (hasSubscription && subscriptionCanceled) {
      return t('settings.pricing.header.multiCanceled', { date: dayjs.unix(subscriptionEndsAt).format('LL') });
    }
    return t('settings.pricing.header.multi', { date: dayjs.unix(subscriptionEndsAt).format('LL') });
  };

  return (
    <RootContainerBox>
      {/* pricing grid header */}
      <HeaderTypography variant="body1">{getHeaderText()}</HeaderTypography>
      <PricingTable isSingle={!hasSubscription} isMulti={hasSubscription} />

      <form action={t('path.backend.subscription', { uid })} method={hasSubscription ? GET : POST}>
        {!hasSubscription && <input type="hidden" id="priceId" name="priceId" value={process.env.STRIPE_MULTI_PRICE_ID} />}
        <RoundedButton
          text={hasSubscription ? t('settings.managePlan') : t('settings.upgradePlan')}
          dataButton="settings-upgrade-button"
          submit
          sx={{ margin: theme.spacing(2, 0) }}
        />
      </form>
    </RootContainerBox>
  );
};

Subscription.defaultProps = {
  hasSubscription: false,
  subscriptionEndsAt: null,
  subscriptionCanceled: false
};

Subscription.propTypes = {
  t: PropTypes.func.isRequired,
  uid: PropTypes.number.isRequired,
  hasSubscription: PropTypes.bool,
  subscriptionCanceled: PropTypes.bool,
  subscriptionEndsAt: PropTypes.number
};

export default withTranslation()(Subscription);
