import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { ContributionLegendRootContainer } from './ContributionLegend.styles';

// cell size
const sz = 13;

const legendMapping = [
  i18n.t('contribution.commit', { n: 'No' }),
  i18n.t('contribution.commit', { n: '1-9' }),
  i18n.t('contribution.commit', { n: '10-19' }),
  i18n.t('contribution.commit', { n: '20-29' }),
  i18n.t('contribution.commit', { n: '30+' })
];

const generateLevel = (level, theme, noHover) => (
  <rect
    key={level}
    className={`${theme} ${noHover ? 'nohover' : ''}`}
    data-level={level}
    x={level * (sz + 2)}
    y={0}
    width={sz}
    height={sz}
  >
    <title>{legendMapping[level]}</title>
  </rect>
);

const ContributionLegend = ({ theme, noHover }) => (
  <ContributionLegendRootContainer>
    <svg width={legendMapping.length * (sz + 2) + 2} height={sz + 4}>
      <g transform="translate(2,2)">{[0, 1, 2, 3, 4].map((level) => generateLevel(level, theme, noHover))}</g>
    </svg>
  </ContributionLegendRootContainer>
);

ContributionLegend.defaultProps = {
  noHover: false
};

ContributionLegend.propTypes = {
  theme: PropTypes.string.isRequired,
  noHover: PropTypes.bool
};

export default memo(ContributionLegend);
