import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Card, CardActions, CardContent, Dialog, InputAdornment, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import validator from 'validator';
import { BP_SM } from '../../../../shared/constants';
import { useScreenSizeDetector } from '../../hooks/useScreenSizeDetector';
import { FlexColumnContainer, FlexRowContainer } from '../../shared/Common.styles';
import Link from '../../shared/Link';
import RoundedTextField from '../../shared/RoundedTextField';

const ChangePasswordDialog = ({ t, onClose, open }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidationError, setPasswordValidationError] = useState(false);
  const oldPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const theme = useTheme();
  const isMobile = useScreenSizeDetector(BP_SM);

  const handleShowPassword = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPasswordValidationError(false);
  };

  const handleChangeButtonClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    // ensure that password is at least 8 char long
    const isPasswordStrong = validator.isStrongPassword(newPasswordRef.current.value);
    if (!isPasswordStrong) {
      setPasswordValidationError(true);
    } else {
      onClose({
        forgotPassword: false,
        oldPassword: oldPasswordRef.current.value,
        newPassword: newPasswordRef.current.value
      });
    }
  };

  const handleDialogEnter = () => {
    setPasswordValidationError(false);
  };

  const handleResetPasswordClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    onClose({ forgotPassword: true });
  };

  const onDialogClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    onClose({ forgotPassword: false });
  };

  const getAuthButtonErrorStyle = (error) => {
    if (error) {
      return {
        '& .MuiFilledInput-root': {
          border: '2px solid rgba(193, 25, 21, 0.75)',
          backgroundColor: 'rgba(193, 25, 21, 0.10)'
        },
        '& .MuiFormHelperText-root': {
          maxWidth: 'fit-content',
          lineHeight: 1.25
        }
      };
    }
    return {};
  };

  return (
    <Dialog
      data-dialog="change-password"
      onClose={onDialogClose}
      fullWidth={isMobile}
      open={open}
      TransitionProps={{ onEnter: handleDialogEnter }}
    >
      <form>
        <Card sx={{ padding: theme.spacing(2, 4) }}>
          <CardContent sx={{ padding: 0, margin: 0 }}>
            <FlexColumnContainer sx={{ alignItems: 'center' }}>
              <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: theme.spacing(3) }}>
                {t('auth.changePassword')}
              </Typography>

              {/* old password field */}
              <FlexColumnContainer sx={{ marginBottom: theme.spacing(2) }}>
                <Typography variant="body2" sx={{ fontWeight: '600' }}>
                  {t('common.password')}
                </Typography>
                <RoundedTextField
                  type={showPassword ? 'text' : 'password'}
                  inputRef={oldPasswordRef}
                  name="old-password"
                  autoComplete="current-password"
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disableFocusRipple
                          disableRipple
                          onClick={handleShowPassword}
                          sx={{
                            textTransform: 'none',
                            color: '#000',
                            fontWeight: '600',
                            '&:hover': { backgroundColor: 'inherit' }
                          }}
                        >
                          {showPassword ? t('auth.hide') : t('auth.show')}
                        </Button>
                      </InputAdornment>
                    )
                  }}
                />
              </FlexColumnContainer>

              {/* new password field */}
              <FlexColumnContainer>
                <FlexRowContainer sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ fontWeight: '600' }}>
                    {t('common.newPassword')}
                  </Typography>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    onClick={handleResetPasswordClick}
                    text={t('auth.forgotPassword')}
                    variant="body2"
                    sx={{ fontWeight: '600', color: 'rgb(0, 0, 0, 0.5)' }}
                  />
                  {/* eslint-enable-next-line jsx-a11y/anchor-is-valid */}
                </FlexRowContainer>
                <RoundedTextField
                  type={showPassword ? 'text' : 'password'}
                  inputRef={newPasswordRef}
                  name="new-password"
                  autoComplete="current-password"
                  helperText={passwordValidationError ? t('error.account.signupPassword') : ''}
                  sx={getAuthButtonErrorStyle(passwordValidationError)}
                  onChange={handlePasswordChange}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disableFocusRipple
                          disableRipple
                          onClick={handleShowPassword}
                          sx={{
                            textTransform: 'none',
                            color: '#000',
                            fontWeight: '600',
                            '&:hover': { backgroundColor: 'inherit' }
                          }}
                        >
                          {showPassword ? t('auth.hide') : t('auth.show')}
                        </Button>
                      </InputAdornment>
                    )
                  }}
                />
              </FlexColumnContainer>
            </FlexColumnContainer>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <FlexRowContainer sx={{ justifyContent: 'space-evenly', width: '100%', marginTop: theme.spacing(1) }}>
              <Button
                autoFocus
                arial-label="change"
                onClick={handleChangeButtonClick}
                startIcon={<CheckRoundedIcon />}
                type="submit"
                data-button="change"
                sx={{ width: '100%' }}
              >
                {t('auth.change')}
              </Button>
              <Button
                autoFocus
                arial-label="cancel"
                onClick={onDialogClose}
                startIcon={<CloseRoundedIcon />}
                data-button="cancel"
                sx={{ width: '100%' }}
              >
                {t('auth.cancel')}
              </Button>
            </FlexRowContainer>
          </CardActions>
        </Card>
      </form>
    </Dialog>
  );
};

ChangePasswordDialog.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default withTranslation()(ChangePasswordDialog);
