import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { Typography, useTheme } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  BP_MOBILE_PORTRAIT,
  CONTRIBUTION_THEME_DEFAULT,
  CONTRIBUTION_THEME_GITHUB,
  CONTRIBUTION_THEME_GITLAB,
  CONTRIBUTION_THEME_THANKSGIVING,
  CONTRIBUTION_THEME_XMAS_GREEN,
  CONTRIBUTION_THEME_XMAS_RED,
  COOKIE_KEY_PREFERRED_THEME
} from '../../../../shared/constants';
import { useScreenSizeDetector } from '../../hooks/useScreenSizeDetector';
import ContributionLegend from './ContributionLegend';
import {
  ContributionLegendListContainer,
  ContributionLegendListRootContainer
} from './ContributionLegendList.styles';

export const THEMES = [
  CONTRIBUTION_THEME_DEFAULT,
  CONTRIBUTION_THEME_GITLAB,
  CONTRIBUTION_THEME_GITHUB,
  CONTRIBUTION_THEME_THANKSGIVING,
  CONTRIBUTION_THEME_XMAS_RED,
  CONTRIBUTION_THEME_XMAS_GREEN
];

export const getPreferredThemeIndex = () => {
  try {
    const index = THEMES.indexOf(Cookies.get(COOKIE_KEY_PREFERRED_THEME));
    return index < 0 ? 0 : index;
  } catch (e) {
    // default theme
    return 0;
  }
};

export const setPreferredTheme = (theme) => {
  try {
    Cookies.set(COOKIE_KEY_PREFERRED_THEME, theme, { expires: 365 });
  } catch (e) {
    // do nothing
  }
};

const sx = {
  fontWeight: 300,
  lineHeight: 'unset'
};

const ContributionLegendList = ({
  t,
  selectedTheme,
  changeTheme,
  total,
  firstActivityAt
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const theme = useTheme();
  const isUnderMobilePortrait = useScreenSizeDetector(BP_MOBILE_PORTRAIT);

  const generateTotalContributionCountText = (count, date) =>
    `${count === 0 ? 'No' : count.toLocaleString()} ${t(
      'contribution.totalCommitWithCount',
      {
        count,
        date
      }
    )}`;

  const onThemeClick = (themeToChange) => () => {
    setOpen(false);
    changeTheme(themeToChange);
    setPreferredTheme(themeToChange);
  };

  const onMenuExpandClick = () => {
    setOpen((popen) => !popen);
  };

  const onClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <ContributionLegendListRootContainer>
      <Typography
        variant="body2"
        gutterBottom={!isUnderMobilePortrait}
        sx={{ fontWeight: 300, marginRight: theme.spacing(1) }}
      >
        {generateTotalContributionCountText(total, firstActivityAt)}
      </Typography>
      <ContributionLegendListContainer>
        <Typography variant="caption" sx={sx}>
          {t('contribution.less')}
        </Typography>
        <ContributionLegend theme={selectedTheme} />
        <Typography variant="caption" sx={sx}>
          {t('contribution.more')}
        </Typography>
        <IconButton
          ref={anchorRef}
          size="small"
          onClick={onMenuExpandClick}
          sx={{ padding: 0 }}
        >
          <ArrowDropDownRoundedIcon />
        </IconButton>
        <Popper
          sx={{ zIndex: 1 }}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={onClose}>
                  <MenuList autoFocusItem>
                    {THEMES.map((th) => (
                      <MenuItem
                        key={th}
                        selected={th === selectedTheme}
                        onClick={onThemeClick(th)}
                        sx={{ minHeight: 'unset' }}
                      >
                        <>
                          <ContributionLegend theme={th} noHover />
                          <Typography variant="caption" sx={sx}>
                            {t(`theme.${th}`)}
                          </Typography>
                        </>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ContributionLegendListContainer>
    </ContributionLegendListRootContainer>
  );
};

ContributionLegendList.propTypes = {
  t: PropTypes.func.isRequired,
  selectedTheme: PropTypes.string.isRequired,
  changeTheme: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  firstActivityAt: PropTypes.string.isRequired
};

export default withTranslation()(ContributionLegendList);
