import axios from 'axios';
import i18n from 'i18next';
import { AXIOS_CONFIG, EMAIL_ADDRESS } from '../../../../shared/constants';
import ThunkError from '../../../shared/errors/ThunkError';
import { set as setFeedback } from '../../reducers/feedback';

const requestPasswordReset = (params) => async (dispatch) => {
  const { email } = params;
  const instance = axios.create(AXIOS_CONFIG);

  try {
    await dispatch(setFeedback({}));
    await instance.post('/api/reset', {
      data: {
        email
      }
    });
  } catch (e) {
    if (e && e.response && e.response.data) {
      const { data: message } = e.response;
      throw new ThunkError(message);
    }
    throw new ThunkError(i18n.t('error.async.unableToRequestPasswordReset', { email: EMAIL_ADDRESS }));
  }
};

export default requestPasswordReset;
