import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const PageContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  fontFamily: 'Cabin'
}));

export const PageContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: theme.spacing(24),
  maxWidth: theme.spacing(45),
  margin: theme.spacing(5, 4),
  width: 'fill-available'
}));

export const FlexColumnContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const FlexRowContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row'
}));
