import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { isNilOrEmpty } from '../../../shared/utils';
import { PageContainer, PageContentContainer } from '../shared/Common.styles';
import { ErrorPageContentContainer, ErrorPageHeader } from './ErrorPage.styles';

const ErrorPage = ({ t, header, message }) => (
  <PageContainer sx={{ marginTop: '15vh', marginBottom: '20vh' }}>
    <PageContentContainer>
      <ErrorPageHeader variant="h5" gutterBottom sx={{ color: 'rgb(0, 0, 0, 0.5)' }}>
        {isNilOrEmpty(header) ? t('error.header') : header}
      </ErrorPageHeader>
      <ErrorPageContentContainer>
        <Typography variant="body1" gutterBottom sx={{ color: 'rgb(0, 0, 0, 0.5)', textAlign: 'center' }}>
          {message}
        </Typography>
      </ErrorPageContentContainer>
    </PageContentContainer>
  </PageContainer>
);

ErrorPage.defaultProps = {
  header: ''
};

ErrorPage.propTypes = {
  t: PropTypes.func.isRequired,
  header: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default withTranslation()(ErrorPage);
