import { Box, Checkbox } from '@mui/material';
import { styled } from '@mui/system';

export const AuthHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(1)
  }
}));

export const RememberMeCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(1)
}));
