import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import validator from 'validator';
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_WARNING
} from '../../../shared/constants';
import { isGitHubLinked, isGitLabLinked, isNilOrEmpty, isUsernameValid } from '../../../shared/utils';
import ResetPasswordDialog from '../auth/reset-password/ResetPasswordDialog';
import ErrorPage from '../error/ErrorPage';
import UnauthorizedPage from '../error/UnauthorizedPage';
import { withAuth } from '../hocs/withAuth';
import { withProgress } from '../hocs/withProgress';
import { withSnackbar } from '../hocs/withSnackbar';
import { parseOAuthResponse, useOAuthResponse } from '../hooks/useOAuthResponse';
import { parseSubscriptionResponse, useSubscriptionResponse } from '../hooks/useSubscriptionResponse';
import { useThunk } from '../hooks/useThunk';
import AlertBanner from '../shared/AlertBanner';
import { FlexColumnContainer, FlexRowContainer, PageContainer, PageContentContainer } from '../shared/Common.styles';
import Link from '../shared/Link';
import RoundedButton from '../shared/RoundedButton';
import RoundedRepoButton from '../shared/RoundedRepoButton';
import RoundedTextField from '../shared/RoundedTextField';
import ChangePasswordDialog from './change-password/ChangePasswordDialog';
import ProviderList from './providers/ProviderList';
import Subscription from './subscriptions/Subscription';

// TODO:
// [x] Error on non-authenticated user access
// [x] Use getUser async thunk to fill name when loaded
// [x] - no need to set feedback and progress, handle them with callback instead
// [x] - display error message properly
// [x] Disable save button until modification is made
// [x] Backend implementation on settings save: /api/settings
// [x] - display error message properly
// [ ] Change password link
// [ ] Email confirmation on password change
// [ ] List of stores or
// [ ] No stores found with make button

const SettingsPage = (props) => {
  const {
    auth: { id, isAuthenticated, hasSubscription, subscriptionCanceled, subscriptionEndsAt },
    user,
    feedback,
    t,
    setProgress,
    setSnackbar,
    changePassword,
    getUserSettings,
    patchUserSettings,
    patchUserRepoLogs,
    deleteUserRepo,
    requestPasswordReset,
    setUsername,
    setName,
    setEmail,
    removeRepo
  } = props;
  const [state, setState] = useState({
    snackbar: {},
    cookieResponse: null,
    saveButtonDisabled: true,
    showChangePasswordDialog: false,
    showResetPasswordDialog: false,
    usernameValidationError: false,
    nameValidationError: false,
    emailValidationError: false
  });
  const usernameRef = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const theme = useTheme();

  useOAuthResponse((response) => {
    if (isNilOrEmpty(response)) {
      // no oauth response found
      return;
    }
    setState({
      ...state,
      cookieResponse: parseOAuthResponse(response)
    });
  });

  useSubscriptionResponse((response) => {
    if (isNilOrEmpty(response)) {
      // no subscription response found
      return;
    }
    setState({
      ...state,
      cookieResponse: parseSubscriptionResponse(response)
    });
  });

  const getParams = useCallback(
    () => ({
      thunk: id ? getUserSettings : null,
      params: {
        data: { id }
      },
      setProgress
    }),
    [getUserSettings, id, setProgress]
  );

  // Use useCallback to keep the function reference identical during render cycle to ensure
  // it's executed only once per render & null thunk is passwed to ignore when not authenticated
  const isThunkCalled = useThunk(getParams);

  const isProfileChanged = () =>
    user.username !== usernameRef.current.value || user.name !== nameRef.current.value || user.email !== emailRef.current.value;

  const handleUsernameChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    setState({
      ...state,
      snackbar: {},
      saveButtonDisabled: !isProfileChanged(),
      usernameValidationError: false
    });
  };

  const handleNameChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    setState({
      ...state,
      snackbar: {},
      saveButtonDisabled: !isProfileChanged(),
      nameValidationError: false
    });
  };

  const handleEmailChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    setState({
      ...state,
      snackbar: {},
      saveButtonDisabled: !isProfileChanged(),
      emailValidationError: false
    });
  };

  const handleSave = () => {
    const isValidUsername = isUsernameValid(usernameRef.current.value);
    const isValidName = !isNilOrEmpty(nameRef.current.value);
    const isValidEmail = validator.isEmail(emailRef.current.value);

    if (!isValidUsername || !isValidName || !isValidEmail) {
      setState({
        ...state,
        snackbar: {},
        usernameValidationError: !isValidUsername,
        nameValidationError: !isValidName,
        emailValidationError: !isValidEmail
      });
      return;
    }

    // save old user value in case update fails
    const oldUserValue = { ...user };

    // set changes in redux
    setUsername(usernameRef.current.value);
    setName(nameRef.current.value);
    setEmail(emailRef.current.value);

    // call patch user thunk
    setProgress(true);
    patchUserSettings(id)
      .then(() => {
        setProgress(false);
        // update state
        setState({
          ...state,
          snackbar: {},
          saveButtonDisabled: true,
          usernameValidationError: false,
          nameValidationError: false,
          emailValidationError: false
        });
      })
      .catch((e) => {
        setProgress(false);
        // set back to old user value
        setUsername(oldUserValue.name);
        setName(oldUserValue.name);
        setEmail(oldUserValue.email);
        // update state
        setState({
          ...state,
          snackbar: {
            severity: ALERT_SEVERITY_ERROR,
            message: e.message
          }
        });
      });
  };

  const getErrorStyle = (error) => {
    if (error) {
      return {
        '& .MuiFilledInput-root': {
          border: '2px solid rgba(193, 25, 21, 0.75)',
          backgroundColor: 'rgba(193, 25, 21, 0.10)'
        }
      };
    }
    return {};
  };

  const unlinkConfirmationSnackbar = (provider, callback) => () => {
    setSnackbar({
      severity: ALERT_SEVERITY_WARNING,
      message: t('notification.oauth.unlinkConfirmation', { provider }),
      action: {
        text: t('settings.unlink'),
        icon: <LogoutRoundedIcon />,
        callback
      }
    });
  };

  const onRepoSyncClick = (rid, provider, repoId, name) => () => {
    setProgress(true);
    patchUserRepoLogs(id, rid, provider, repoId, name)
      .then(() => {
        setState({
          ...state,
          snackbar: {
            severity: ALERT_SEVERITY_SUCCESS,
            message: t('notification.settings.syncRepoSuccess', { name })
          }
        });
      })
      .catch((e) => {
        setState({
          ...state,
          snackbar: {
            severity: ALERT_SEVERITY_ERROR,
            message: e.message
          }
        });
      })
      .finally(() => {
        setProgress(false);
      });
  };

  const handleRemoveRepo = (rid, provider, name) => () => {
    setProgress(true);
    deleteUserRepo(id, rid, provider, name)
      .then(() => {
        removeRepo(rid);
        setState({
          ...state,
          snackbar: {
            severity: ALERT_SEVERITY_SUCCESS,
            message: t('notification.settings.removeRepoSuccess', { name })
          }
        });
      })
      .catch((e) => {
        setState({
          ...state,
          snackbar: {
            severity: ALERT_SEVERITY_ERROR,
            message: e.message
          }
        });
      })
      .finally(() => {
        setProgress(false);
      });
  };

  const onRepoRemoveClick = (rid, provider, name) => () => {
    setSnackbar({
      severity: ALERT_SEVERITY_WARNING,
      message: t('notification.settings.removeRepoConfirmation', { name }),
      action: {
        text: t('settings.remove'),
        icon: <DeleteForeverRoundedIcon />,
        callback: handleRemoveRepo(rid, provider, name)
      }
    });
  };

  const githubAppInstallAction = () => (
    <Button href={`${process.env.GITHUB_APP_INSTALLATION_LINK}?target_id=${user.githubOAuthId}`} color="inherit" size="small">
      {t('settings.install')}
    </Button>
  );

  const handleChangePasswordClick = () => {
    setState((pstate) => ({
      ...pstate,
      showChangePasswordDialog: true
    }));
  };

  const handleChangePasswordDialogClose = ({ forgotPassword, oldPassword, newPassword }) => {
    setState((pstate) => ({ ...pstate, showChangePasswordDialog: false }));

    if (forgotPassword) {
      setState((pstate) => ({
        ...pstate,
        showChangePasswordDialog: false,
        showResetPasswordDialog: true
      }));
    }

    if (!forgotPassword && !isNilOrEmpty(oldPassword) && !isNilOrEmpty(newPassword)) {
      setProgress(true);
      changePassword({ id, oldPassword, newPassword })
        .then(() => {
          setState((pstate) => ({
            ...pstate,
            snackbar: {
              message: t('notification.passwordChangeSuccess'),
              severity: ALERT_SEVERITY_SUCCESS
            },
            showChangePasswordDialog: false
          }));
        })
        .catch((e) => {
          setState((pstate) => ({
            ...pstate,
            snackbar: {
              message: e.message,
              severity: ALERT_SEVERITY_ERROR
            },
            showChangePasswordDialog: false
          }));
        })
        .finally(() => {
          setProgress(false);
        });
    }
  };

  const handleResetPasswordDialogClose = (email) => {
    setState((pstate) => ({ ...pstate, showResetPasswordDialog: false }));

    if (isNilOrEmpty(email)) {
      return;
    }

    setProgress(true);
    requestPasswordReset({ email })
      .then(() => {
        setState((pstate) => ({
          ...pstate,
          snackbar: {
            message: t('notification.resetEmailSuccess'),
            severity: ALERT_SEVERITY_SUCCESS
          },
          showResetPasswordDialog: false
        }));
      })
      .catch((e) => {
        setState((pstate) => ({
          ...pstate,
          snackbar: {
            message: e.message,
            severity: ALERT_SEVERITY_ERROR
          },
          showResetPasswordDialog: false
        }));
      })
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <>
      {!isAuthenticated && <UnauthorizedPage message={t('error.auth.toAccessSettingsPage')} />}
      {isAuthenticated && isThunkCalled && !user && feedback && <ErrorPage message={feedback.message} />}
      {isAuthenticated && isThunkCalled && user && (
        <PageContainer>
          <PageContentContainer>
            {!isNilOrEmpty(state.snackbar) && (
              <AlertBanner gutterBottom message={state.snackbar.message} severity={state.snackbar.severity} />
            )}
            {state.cookieResponse && (
              <AlertBanner gutterBottom message={state.cookieResponse.message} severity={state.cookieResponse.severity} />
            )}
            {isGitHubLinked(user.oauths) && !user.githubAppInstalled && (
              <AlertBanner
                gutterBottom
                message={t('notification.settings.githubAppInstallation')}
                severity={ALERT_SEVERITY_INFO}
                shouldHaveCustomAction
                customAction={githubAppInstallAction()}
              />
            )}
            {/* change password dialog */}
            <ChangePasswordDialog onClose={handleChangePasswordDialogClose} open={state.showChangePasswordDialog} />
            {/* reset password dialog */}
            <ResetPasswordDialog
              onClose={handleResetPasswordDialogClose}
              open={state.showResetPasswordDialog}
              defaultEmail={user.email}
            />
            {/* settings header */}
            <Typography variant="h5" gutterBottom sx={{ fontWeight: '700', marginBottom: theme.spacing(3) }}>
              {t('settings.settings')}
            </Typography>

            <Box style={{ width: '100%' }}>
              {/* username input field */}
              <FlexColumnContainer sx={{ marginBottom: theme.spacing(2) }}>
                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                  {t('common.username')}
                </Typography>
                <RoundedTextField
                  inputRef={usernameRef}
                  name="username"
                  autoComplete="username"
                  defaultValue={user.username}
                  helperText={state.usernameValidationError ? t('error.account.username') : ''}
                  sx={getErrorStyle(state.usernameValidationError)}
                  onChange={handleUsernameChange}
                />
              </FlexColumnContainer>

              {/* name input field */}
              <FlexColumnContainer sx={{ marginBottom: theme.spacing(2) }}>
                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                  {t('common.name')}
                </Typography>
                <RoundedTextField
                  inputRef={nameRef}
                  name="name"
                  autoComplete="name"
                  defaultValue={user.name}
                  helperText={state.nameValidationError ? t('error.account.name') : ''}
                  sx={getErrorStyle(state.nameValidationError)}
                  onChange={handleNameChange}
                />
              </FlexColumnContainer>

              {/* email input field & change password button */}
              <FlexColumnContainer sx={{ marginBottom: theme.spacing(2) }}>
                <FlexRowContainer sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    {t('common.email')}
                  </Typography>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    onClick={handleChangePasswordClick}
                    text={t('common.changePassword')}
                    variant="body1"
                    sx={{ fontWeight: '600', color: 'rgb(0, 0, 0, 0.5)' }}
                  />
                  {/* eslint-enable-next-line jsx-a11y/anchor-is-valid */}
                </FlexRowContainer>
                <RoundedTextField
                  inputRef={emailRef}
                  name="email"
                  autoComplete="email"
                  defaultValue={user.email}
                  helperText={state.emailValidationError ? t('error.account.email') : ''}
                  sx={getErrorStyle(state.emailValidationError)}
                  onChange={handleEmailChange}
                />
              </FlexColumnContainer>

              {/* save button */}
              <RoundedButton
                text={t('settings.save')}
                disabled={state.saveButtonDisabled}
                dataButton="settings-save-button"
                onClick={handleSave}
                sx={{ margin: theme.spacing(2, 0) }}
              />

              {/* divider with list of providers */}
              <Divider sx={{ opacity: '0.5', fontSize: 'small', margin: theme.spacing(2, 0) }}>
                {t('settings.providers')}
              </Divider>

              {/* list of providers */}
              <ProviderList
                gitlab={isGitLabLinked(user.oauths)}
                github={isGitHubLinked(user.oauths)}
                unlinkConfirmationSnackbar={unlinkConfirmationSnackbar}
              />

              {/* divider with list of providers */}
              <Divider sx={{ opacity: '0.5', fontSize: 'small', margin: theme.spacing(2, 0) }}>
                {t('settings.repositories')}
              </Divider>

              <FlexColumnContainer
                sx={{
                  margin: theme.spacing(4, 0),
                  padding: 0,
                  '& >:not(:last-child)': {
                    marginBottom: theme.spacing(1)
                  },
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {user.repos.length === 0 ? (
                  <Typography noWrap variant="caption" component="p">
                    {t('settings.noRepoYet')}
                  </Typography>
                ) : (
                  user.repos.map(({ id: rid, provider, repoId, name, pathWithNamespace, avatarUrl }) => (
                    <RoundedRepoButton
                      key={rid}
                      name={name}
                      avatarUrl={avatarUrl}
                      onSyncClick={onRepoSyncClick(rid, provider, repoId, pathWithNamespace)}
                      onRemoveClick={onRepoRemoveClick(rid, provider, pathWithNamespace)}
                    />
                  ))
                )}
              </FlexColumnContainer>

              {/* divider with list of providers */}
              <Divider sx={{ opacity: '0.5', fontSize: 'small', margin: theme.spacing(2, 0) }}>
                {t('settings.subscription')}
              </Divider>

              <Subscription
                uid={id}
                hasSubscription={hasSubscription}
                subscriptionCanceled={subscriptionCanceled}
                subscriptionEndsAt={subscriptionEndsAt}
              />
            </Box>
          </PageContentContainer>
        </PageContainer>
      )}
    </>
  );
};

SettingsPage.defaultProps = {
  auth: {
    id: null,
    isAuthenticated: false
  },
  user: null,
  feedback: null
};

SettingsPage.propTypes = {
  auth: PropTypes.shape({
    id: PropTypes.number,
    isAuthenticated: PropTypes.bool,
    hasSubscription: PropTypes.bool,
    subscriptionCanceled: PropTypes.bool,
    subscriptionEndsAt: PropTypes.number
  }),
  user: PropTypes.shape({
    username: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    oauths: PropTypes.arrayOf(PropTypes.string),
    repos: PropTypes.arrayOf(PropTypes.shape({})),
    githubAppInstalled: PropTypes.bool,
    githubOAuthId: PropTypes.number
  }),
  feedback: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string
  }),
  t: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  getUserSettings: PropTypes.func.isRequired,
  patchUserSettings: PropTypes.func.isRequired,
  patchUserRepoLogs: PropTypes.func.isRequired,
  deleteUserRepo: PropTypes.func.isRequired,
  requestPasswordReset: PropTypes.func.isRequired,
  setUsername: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  removeRepo: PropTypes.func.isRequired
};

export default withSnackbar()(withProgress()(withAuth()(withTranslation()(SettingsPage))));
