import { useCallback, useEffect, useState } from 'react';

export const useThunk = (getParams) => {
  const [executed, setExecuted] = useState(false);

  const execThunk = useCallback((thunk, params, setProgress, options) => {
    const { onSuccess, onFailure } = options || {};

    setProgress(true);

    thunk(params)
      .then((data) => {
        if (onSuccess) {
          onSuccess(data);
        }
      })
      .catch((e) => {
        if (onFailure) {
          onFailure(e);
        }
      })
      .finally(() => {
        setProgress(false);
        setExecuted(true);
      });
  }, []);

  useEffect(() => {
    const { thunk, params, setProgress, options } = getParams();

    if (!thunk) {
      return;
    }

    execThunk(thunk, params, setProgress, options);
  }, [getParams, execThunk]);

  return executed;
};
