import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { RoundedButton as RoundedButtonStyled, RoundedButtonContainer, RoundedButtonIcon } from './RoundedButton.styles';

const RoundedButton = ({ text, href, iconSrc, dataButton, submit, disabled, size, sx, onClick }) => (
  <RoundedButtonStyled
    variant="outlined"
    size={size}
    type={submit ? 'submit' : 'button'}
    disabled={disabled}
    data-button={dataButton}
    href={href}
    sx={sx}
    onClick={onClick}
  >
    <RoundedButtonContainer>
      {iconSrc && <RoundedButtonIcon variant="square" src={iconSrc} alt={text} />}
      <Typography noWrap variant={size === 'large' ? 'body1' : 'caption'} component="p" sx={{ lineHeight: 'unset' }}>
        {text}
      </Typography>
    </RoundedButtonContainer>
  </RoundedButtonStyled>
);

RoundedButton.defaultProps = {
  href: null,
  iconSrc: null,
  dataButton: 'button',
  submit: false,
  disabled: false,
  size: 'large',
  sx: {},
  onClick: null
};

RoundedButton.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  iconSrc: PropTypes.string,
  dataButton: PropTypes.string,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  sx: PropTypes.shape({}),
  onClick: PropTypes.func
};

export default RoundedButton;
