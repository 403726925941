import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const RepoHeaderRootContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(1)
  }
}));

export const RepoHeaderNameContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(1)
  },
  marginBottom: theme.spacing(0.5)
}));
