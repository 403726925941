import { ThemeProvider } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { theme } from '../../../themes/AppThemes';
import { CookieConsent as CookieConsentStyled, CookieConsentLink } from './CookieConsent.styles';

const CookieConsent = ({ t }) => (
  <ThemeProvider theme={theme}>
    <CookieConsentStyled>
      {t('cookieConsent.line1')}
      <CookieConsentLink component="a" href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
        {t('cookieConsent.termsAndConditions')}
      </CookieConsentLink>
      {t('cookieConsent.line2')}
      <CookieConsentLink component="a" href="/privacy-policy" target="_blank" rel="noopener noreferrer">
        {t('cookieConsent.privacyPolicy')}
      </CookieConsentLink>
      {t('cookieConsent.line3')}
    </CookieConsentStyled>
  </ThemeProvider>
);

CookieConsent.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(CookieConsent);
