import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {}
};

const setUserAttribute = (state, attribute, payload) => {
  const data = {
    user: {
      ...state.data.user
    }
  };
  data.user[attribute] = payload;
  return data;
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set(state, action) {
      state.data = action.payload;
    },
    setUsername(state, action) {
      state.data = setUserAttribute(state, 'username', action.payload);
    },
    setName(state, action) {
      state.data = setUserAttribute(state, 'name', action.payload);
    },
    setEmail(state, action) {
      state.data = setUserAttribute(state, 'email', action.payload);
    },
    removeRepo(state, action) {
      const nstate = {
        user: {
          ...state.data.user,
          repos: state.data.user.repos.filter(({ id }) => id !== action.payload)
        }
      };
      state.data = nstate;
    }
  }
});

export const { set, setUsername, setName, setEmail, removeRepo } = slice.actions;
export default slice.reducer;
