import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return props.children;
};

ScrollToTop.propTypes = {
  children: PropTypes.shape({})
};

export default ScrollToTop;
