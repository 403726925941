import PropTypes from 'prop-types';
import React from 'react';
import { RoundedTextField as RoundedTextFieldStyled } from './RoundedTextField.styles';

const RoundedTextField = ({
  type,
  helperText,
  name,
  autoComplete,
  defaultValue,
  inputProps,
  inputRef,
  onChange,
  disabled,
  sx
}) => (
  <RoundedTextFieldStyled
    variant="filled"
    size="small"
    name={name}
    type={type}
    autoComplete={autoComplete}
    defaultValue={defaultValue}
    helperText={helperText}
    InputProps={inputProps}
    inputRef={inputRef}
    onChange={onChange}
    disabled={disabled}
    sx={sx}
  />
);

RoundedTextField.defaultProps = {
  type: 'text',
  helperText: '',
  autoComplete: '',
  defaultValue: '',
  onChange: () => {},
  inputProps: null,
  inputRef: null,
  disabled: false,
  sx: {}
};

RoundedTextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string,
  helperText: PropTypes.string,
  inputProps: PropTypes.shape({}),
  inputRef: PropTypes.shape({}),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  sx: PropTypes.shape({})
};

export default RoundedTextField;
