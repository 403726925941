import { Button, Slide, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import { ALERT_SEVERITY_NONE } from '../../../shared/constants';
import { getWrappedComponentDisplayName } from '../../../shared/utils';
import { AlertStyled, BackdropStyled } from './withSnackbar.styles';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'center'
};

const autoHideDuration = 30000; // 30s

const TransitionComponent = (props) => <Slide {...props} direction="down" />;

const withSnackbar = () => (WrappedComponent) => {
  const WithSnackbar = (props) => {
    const [state, setState] = useState({
      open: false,
      severity: ALERT_SEVERITY_NONE,
      message: '',
      action: null
    });

    const onClose = () => {
      setState({ ...state, open: false });
    };

    const setSnackbar = ({ message, severity, action }) => {
      setState({
        open: true,
        severity,
        message,
        action
      });
    };

    return (
      <>
        <BackdropStyled open={state.open} onClick={onClose}>
          <Snackbar
            anchorOrigin={anchorOrigin}
            open={state.open}
            autoHideDuration={autoHideDuration}
            TransitionComponent={TransitionComponent}
          >
            {state.action ? (
              <AlertStyled
                severity={state.severity}
                action={
                  <Button
                    color="inherit"
                    size="small"
                    startIcon={state.action.icon}
                    onClick={() => {
                      onClose();
                      state.action.callback();
                    }}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {state.action.text}
                  </Button>
                }
              >
                {state.message}
              </AlertStyled>
            ) : (
              <AlertStyled onClose={onClose} severity={state.severity}>
                {state.message}
              </AlertStyled>
            )}
          </Snackbar>
        </BackdropStyled>
        <WrappedComponent setSnackbar={setSnackbar} closeSnackbar={onClose} {...props} />
      </>
    );
  };

  WithSnackbar.displayName = `WithSnackbar(${getWrappedComponentDisplayName(WrappedComponent)})`;

  return WithSnackbar;
};

export { withSnackbar };
