import React from 'react';
import { useLocation } from 'react-router-dom';
import { extractUsername, getWrappedComponentDisplayName, isNilOrEmpty } from '../../../shared/utils';
import PageNotFound from '../error/PageNotFound';

const withUsername = () => (WrappedComponent) => {
  const WithUsername = (props) => {
    const { pathname } = useLocation();
    const username = extractUsername(pathname);

    return isNilOrEmpty(username) ? <PageNotFound /> : <WrappedComponent username={username} {...props} />;
  };

  WithUsername.displayName = `WithAuth(${getWrappedComponentDisplayName(WrappedComponent)})`;

  return WithUsername;
};

export { withUsername };
