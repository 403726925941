import axios from 'axios';
import i18n from 'i18next';
import { AXIOS_CONFIG } from '../../../../shared/constants';
import { set as setFeedback } from '../../reducers/feedback';
import { set as setData } from '../../reducers/user';
import { getErrorFeedback } from '../../utils';

const getUserSettings = (params) => async (dispatch, getState) => {
  const {
    user: { data }
  } = getState();

  const {
    data: { id }
  } = params;
  const instance = axios.create(AXIOS_CONFIG);
  const actions = [];

  try {
    await dispatch(setFeedback(null));
    const resp = await instance.get(`/api/u/${id}/settings`);
    const { data: user } = resp;
    // set user data
    actions.push(dispatch(setData({ ...data, user })));
  } catch (e) {
    const feedback = getErrorFeedback(e, i18n.t('error.async.unableToGetUserProfile'));
    // set error feedback state
    actions.push(dispatch(setFeedback(feedback)));
    // then re-throw the error
    throw new Error(feedback.message);
  }
  Promise.all(actions).then(() => {});
};

export default getUserSettings;
