import { createSlice } from '@reduxjs/toolkit';
import { PROGRESS_AUTH_USER_IN_PROGRESS } from '../../../shared/constants';

const initialState = {
  data: {},
  progress: PROGRESS_AUTH_USER_IN_PROGRESS
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    set(state, action) {
      state.data = action.payload;
    },
    progress(state, action) {
      state.progress = action.payload;
    }
  }
});

export const { set, progress } = slice.actions;
export default slice.reducer;
