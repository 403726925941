import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { BP_MOBILE_PORTRAIT } from '../../../shared/constants';

export const Footer = styled(Box)(({ theme }) => ({
  fontFamily: 'Roboto,sans-serif',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginBottom: theme.spacing(0.5)
  },
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down(BP_MOBILE_PORTRAIT)]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4)
  },
  backgroundColor: 'rgba(75, 60, 72, 0.05)'
}));
