import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import IconButton from '../../shared/IconButton';
import { MediumSvgIcon, SnsList as SnsListStyled } from './SnsList.styles';

const SnsList = ({ t }) => (
  <>
    <Typography variant="caption" component="span">
      {t('footer.followUsOn')}
    </Typography>
    <SnsListStyled>
      <IconButton href={t('sns.medium')} icon={<MediumSvgIcon />} />
      <IconButton href={t('sns.twitter')} icon={<TwitterIcon color="primary" />} />
    </SnsListStyled>
  </>
);

SnsList.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(SnsList);
