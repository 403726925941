import getAuthUser from '../thunks/user/getAuthUser';
import { completeAuthProgress } from '../utils';

export default async (initialState, store) => {
  const getAuthenticatedUserThunk = store.dispatch(getAuthUser());

  Promise.all([getAuthenticatedUserThunk])
    .then(() => {
      completeAuthProgress(store.dispatch);
    })
    .catch(() => {
      // TODO: set app error in case of fatal failures
    });
};
