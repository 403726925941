import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import i18n from 'i18next';
import React, { memo } from 'react';
import { isNilOrEmpty, isTouchDevice } from '../../../../shared/utils';
import { FlexColumnContainer } from '../../shared/Common.styles';
import ContributionLegendList from '../contribution-legend/ContributionLegendList';

// dayjs plugins
dayjs.extend(dayOfYear);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);

export const generateContributionCountText = (count) =>
  `${count === 0 ? 'No' : count.toLocaleString()} ${i18n.t('contribution.commitWithCount', { count })}`;

// cell size
// - mobile : with 4 months each row => max 20 + 2 (buffer) weeks * (14+2) = 352px < BP_MOBILE_PORTRAIT: 478px
// - tablet : with 6 months each row => max 28 + 2 (buffer) weeks * (14+2) = 480px < BP_MOBILE_LANDSCAPE: 767px
// - desktop: with the entire year   => max 54 + 2 (buffer) weeks * (14+2) = 896px
const sz = 13;

// 0: no contribution
// 1: 1-9 contributions
// 2: 10-19 contributions
// 3: 20-29 contributions
// 4: 30+ contributions
const getLevel = (contributions) => {
  if (contributions > 0 && contributions < 10) {
    return 1;
  }
  if (contributions >= 10 && contributions < 20) {
    return 2;
  }
  if (contributions >= 20 && contributions < 30) {
    return 3;
  }
  return 4;
};

const generateDay = (key, day, mapping, theme, openContributionDialog) => (
  <rect
    key={key}
    data-date={key}
    className={theme}
    data-level={mapping[key] ? getLevel(mapping[key]) : 0}
    x={0}
    y={(day + 1) * (sz + 2)}
    width={sz}
    height={sz}
    onClick={() => {
      if (isTouchDevice()) {
        openContributionDialog(key, mapping[key]);
      }
    }}
  >
    <title>{`${key}\n${generateContributionCountText(mapping[key] ? mapping[key] : 0)}`}</title>
  </rect>
);

const generateWeek = (key, week, days, split) => (
  <g key={key} transform={`translate(${(week + 1) * (sz + 2)},${split * (8 * (sz + 2))})`}>
    {days.map((cell) => cell)}
  </g>
);

const generateDayText = (dayText, day, split) => (
  <text className="text" key={dayText} x={0} y={(day + 1) * (sz + 2) + split * (8 * (sz + 2))} dominantBaseline="hanging">
    {dayText}
  </text>
);

const generateMonth = (monthText, week, split) => (
  <text className="text" key={monthText} x={(week + 1) * (sz + 2)} y={split * (8 * (sz + 2))} dominantBaseline="hanging">
    {monthText}
  </text>
);

// args
// - year   : target year to generate
// - pairs  : pairs of beg and end combo for each row of block of weeks
// - mapping: containing commits
// - theme  : theme to use
// - openContributionDialog: func to open contribution dialog
const generateYear = (year, pairs, mapping, theme, openContributionDialog) => {
  const weeks = []; // containing vertical week strips
  const months = []; // containing horizontal month text strip on top of blocks of weeks
  let ncommits = 0; // total number of commits in the year
  let nweek = 0; // keeping track of week number for the use of key
  let maxWeeks = 0; // max. number of weeks in a given row

  // loop through each pair of beg and end combo
  pairs.forEach(({ beg, end }, index) => {
    // day of week of the start date to offset when calculating the beginning of week
    const dayOffset = beg.day();
    // week of the year of the start date
    let week = Math.floor((beg.dayOfYear() - 1) / 7);
    // week of the year to offset when generating week's contribution
    const weekOffset = week;

    // initial value
    let month = beg.month();
    let days = [];
    // generate the first month text that will go on top of blocks of weeks
    months.push(generateMonth(beg.format('MMM'), 0, index));

    // go through each day within the beg and end range
    for (let d = beg.clone(); d.isSameOrBefore(end); d = d.add(1, 'day')) {
      // day of week
      const day = d.day();
      // check whether it's time to generate the vertical week strip
      if (day % 7 === 0 && days.length > 0) {
        // beginning of week: SUNDAY
        weeks.push(generateWeek(nweek, week - weekOffset, days, index));
        nweek += 1;
        // clear the days list
        days = [];
        // re-calculate the current week
        week = Math.floor((d.dayOfYear() - 1 + dayOffset) / 7);
        // generate month text when month changes
        if (d.month() > month) {
          months.push(generateMonth(d.format('MMM'), week - weekOffset, index));
          month = d.month();
        }
      }

      // get the day contribution cell/tile with commits
      const key = d.format('YYYY-MM-DD');
      days.push(generateDay(key, day, mapping, theme, openContributionDialog));
      ncommits += mapping[key] ? mapping[key] : 0;
    }

    // generate the last week if found any trailing days
    if (days.length > 0) {
      weeks.push(generateWeek(nweek, week - weekOffset, days, index));
      nweek += 1;
    }

    // calculate the max. number of weeks for the purpose of calculating the total width of svg
    maxWeeks = Math.max(maxWeeks, week - weekOffset);
  });

  // take into account the first column of week text and week starts with 1
  maxWeeks += 2;

  return {
    count: ncommits,
    graph: (
      <FlexColumnContainer key={year}>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: 300 }}>
          {`${year}: ${generateContributionCountText(ncommits)}`}
        </Typography>
        <svg width={maxWeeks * (sz + 2)} height={pairs.length * (8 * (sz + 2))}>
          {weeks.map((strip) => strip)}
          <g>{months.map((mon) => mon)}</g>
          {pairs.map(({ beg }, index) => (
            <g key={beg.format('MMDD')}>
              {[
                { text: 'M', day: 1 },
                { text: 'W', day: 3 },
                { text: 'F', day: 5 }
              ].map(({ text, day }) => generateDayText(text, day, index))}
            </g>
          ))}
        </svg>
      </FlexColumnContainer>
    )
  };
};

// based on all data: multi repo
const getBegFromAll = (data) => {
  const epochs = data.filter(({ firstActivityAt }) => firstActivityAt).map(({ firstActivityAt }) => firstActivityAt);
  if (isNilOrEmpty(epochs)) {
    return dayjs();
  }
  const beg = Math.min(...epochs);
  return dayjs.unix(beg);
};

// based on a single repo
const getBeg = (data) => {
  if (data && data.firstActivityAt) {
    return dayjs.unix(data.firstActivityAt);
  }
  return dayjs();
};

const parseLogs = (logs) => {
  // convert epoch list from the response to object with counts based on GMT
  const days = logs.map((epoch) => dayjs.unix(epoch).format('YYYY-MM-DD'));
  const mapping = {};
  days.forEach((day) => {
    if (mapping[day]) {
      mapping[day] += 1;
    } else {
      mapping[day] = 1;
    }
  });
  return mapping;
};

const getPairs = (year, date, isUnderMobileSize, isUnderTabletSize) => {
  const range = [];
  if (isUnderMobileSize && isUnderTabletSize) {
    // mobile: 4 months in each row
    range.push({ beg: dayjs(`${year}-01-01`), end: dayjs(`${year}-04-30`) });
    range.push({ beg: dayjs(`${year}-05-01`), end: dayjs(`${year}-08-31`) });
    range.push({ beg: dayjs(`${year}-09-01`), end: dayjs(`${year}-12-31`) });
  } else if (!isUnderMobileSize && isUnderTabletSize) {
    // tablet: 2 months in each row
    range.push({ beg: dayjs(`${year}-01-01`), end: dayjs(`${year}-06-30`) });
    range.push({ beg: dayjs(`${year}-07-01`), end: dayjs(`${year}-12-31`) });
  } else if (!isUnderMobileSize && !isUnderTabletSize) {
    // desktop: entire months in each row
    range.push({ beg: dayjs(`${year}-01-01`), end: dayjs(`${year}-12-31`) });
  } else {
    // desktop: entire months in each row
    range.push({ beg: dayjs(`${year}-01-01`), end: dayjs(`${year}-12-31`) });
  }

  return range
    .map(({ beg, end }) => {
      // handles the range where date falls in
      if (date.isBetween(beg, end, null, '[]')) {
        return { beg, end: date.isSameOrBefore(end) ? date : end };
      }
      // handles the range where date is after
      if (date.isAfter(end)) {
        return { beg, end };
      }
      // ignore the remaining days
      return {};
    })
    .filter(({ beg, end }) => beg && end);
};

const ContributionCalendar = ({
  data,
  index,
  theme,
  changeTheme,
  isUnderMobileSize,
  isUnderTabletSize,
  openContributionDialog
}) => {
  const isAll = data.length > 1 && index === 0 && isNilOrEmpty(data[index].logs);
  const mapping = isAll ? parseLogs(data.slice(1).flatMap(({ logs }) => logs)) : parseLogs(data[index].logs);
  const beg = isAll ? getBegFromAll(data) : getBeg(data[index]);
  const end = dayjs();
  const years = [];

  let total = 0;
  for (let year = beg.year(); year <= end.year(); year += 1) {
    const pairs = getPairs(
      year,
      year === end.year() ? end.clone() : dayjs(`${year}-12-31`),
      isUnderMobileSize,
      isUnderTabletSize
    );
    const { count, graph } = generateYear(year, pairs, mapping, theme, openContributionDialog);
    total += count;
    years.unshift(graph);
  }

  // add contribution legend list controller
  years.push(
    <ContributionLegendList
      key="legend"
      selectedTheme={theme}
      changeTheme={changeTheme}
      total={total}
      firstActivityAt={beg.format('LL')}
    />
  );

  return years;
};

export default memo(ContributionCalendar);
