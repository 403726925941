import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Card, CardActions, CardContent, Dialog, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { GITHUB, GITLAB } from '../../../../shared/constants';
import { isNilOrEmpty } from '../../../../shared/utils';
import { FlexColumnContainer, FlexRowContainer } from '../../shared/Common.styles';
import ProviderButton from './providers/ProviderButton';
import RepoItemList from './RepoItemList';

// TODO
// [ ] sorting by last active time
// [ ] more styling: e.g. fixed height

const RepoDialog = ({
  t,
  open,
  startListingFrom,
  onClose,
  username,
  oauths,
  getRepos,
  existingRepos,
  onClick,
  linkConfirmationSnackbar
}) => {
  const [repos, setRepos] = useState(null);
  const theme = useTheme();

  const onSuccess = ({ provider, data }) => {
    setRepos({
      provider,
      data: data.filter(({ id }) => existingRepos.findIndex((repo) => repo.id === id && repo.provider === provider) < 0)
    });
  };

  const onProviderClick = (provider) => () => {
    if (oauths[provider]) {
      // repo already linked, continue getting the list of repos
      getRepos(provider, onSuccess);
    } else {
      // show link confirmation and re-direct to provider signin page
      linkConfirmationSnackbar(t(`common.${provider}`), () => {
        window.location.href = `${t(`path.backend.auth.${provider}.signin`)}?redirectTo=/${username}`;
      })();
    }
  };

  const resetRepos = () => {
    if (startListingFrom) {
      getRepos(startListingFrom, onSuccess);
    } else {
      setRepos(null);
    }
  };

  const repoListView = () => (
    <FlexColumnContainer sx={{ alignItems: 'center' }}>
      <Typography variant="h6" sx={{ fontWeight: '600' }}>
        {`${repos.provider.toUpperCase()} ${t('userPage.repos')}`}
      </Typography>
      {isNilOrEmpty(repos.data) ? (
        <Typography
          variant="body1"
          sx={{
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(8),
            marginRight: theme.spacing(8)
          }}
        >
          {t('userPage.noReposFound')}
        </Typography>
      ) : (
        <RepoItemList repos={repos.data} onClick={onClick} />
      )}
    </FlexColumnContainer>
  );

  const repoProviderSelectView = () => (
    <FlexColumnContainer>
      <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: theme.spacing(3) }}>
        {t('userPage.selectRepoProvider')}
      </Typography>
      <FlexRowContainer sx={{ justifyContent: 'center' }}>
        <ProviderButton
          iconSrc={t('auth.gitlabOAuth.iconSrc')}
          iconAlt={t('auth.gitlabOAuth.iconAlt')}
          onClick={onProviderClick(GITLAB)}
        />
        &nbsp; &nbsp;
        <ProviderButton
          iconSrc={t('auth.githubOAuth.iconSrc')}
          iconAlt={t('auth.githubOAuth.iconAlt')}
          onClick={onProviderClick(GITHUB)}
        />
      </FlexRowContainer>
    </FlexColumnContainer>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEnter: resetRepos
      }}
    >
      <Card sx={{ padding: theme.spacing(2, 4) }}>
        <CardContent sx={{ padding: 0, margin: 0 }}>
          {repos && repoListView()}
          {!repos && repoProviderSelectView()}
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button onClick={onClose} startIcon={<CloseRoundedIcon />} sx={{ width: '100%' }}>
            {t('userPage.close')}
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
};

RepoDialog.defaultProps = {
  oauths: {},
  startListingFrom: null,
  existingRepos: []
};

RepoDialog.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  startListingFrom: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  oauths: PropTypes.shape({}),
  existingRepos: PropTypes.arrayOf(
    PropTypes.shape({
      repoId: PropTypes.number,
      provider: PropTypes.string
    })
  ),
  getRepos: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  linkConfirmationSnackbar: PropTypes.func.isRequired
};

export default withTranslation()(RepoDialog);
