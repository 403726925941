import axios from 'axios';
import i18n from 'i18next';
import { AXIOS_CONFIG } from '../../../../../shared/constants';
import ThunkError from '../../../../shared/errors/ThunkError';
import { set as setFeedback } from '../../../reducers/feedback';

const postUserRepoLogs = (params) => async (dispatch) => {
  const { uid, rid, ...rest } = params;
  const instance = axios.create(AXIOS_CONFIG);

  try {
    await dispatch(setFeedback(null));
    const resp = await instance.post(`/api/u/${uid}/repo/${rid}/log`, {
      data: rest
    });
    const { data } = resp;
    return data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      const { status, data: message } = e.response;
      if (status === 401 || status === 403) {
        throw new ThunkError(i18n.t('notification.oauth.sessionExpired', { provider: i18n.t(`common.${rest.provider}`) }));
      }
      // re-throw the error
      throw new ThunkError(message);
    }
    throw new ThunkError(i18n.t('error.async.unableToGetUserRepoLogs'));
  }
};

export default postUserRepoLogs;
