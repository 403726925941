import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { AlertTitle, Collapse, IconButton, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AlertBanner as AlertBannerStyled, AlertStyled } from './AlertBanner.styles';

const AlertBanner = ({
  title,
  message,
  severity,
  iconMapping,
  gutterBottom,
  shouldHaveClose,
  shouldHaveCustomAction,
  customAction
}) => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();

  const handleCloseClick = () => {
    setOpen(false);
  };

  const getAction = () => {
    if (shouldHaveClose) {
      return (
        <IconButton aria-label="close" color="inherit" size="small" onClick={handleCloseClick}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      );
    }

    if (shouldHaveCustomAction) {
      return customAction;
    }

    return null;
  };

  return (
    <AlertBannerStyled sx={{ marginBottom: gutterBottom ? theme.spacing(3) : 0 }}>
      <Collapse in={open}>
        <AlertStyled severity={severity} action={getAction()} iconMapping={iconMapping}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </AlertStyled>
      </Collapse>
    </AlertBannerStyled>
  );
};

AlertBanner.defaultProps = {
  title: '',
  iconMapping: {
    success: <CheckCircleOutlinedIcon fontSize="inherit" />,
    error: <ErrorOutlineOutlinedIcon fontSize="inherit" />,
    warning: <WarningAmberOutlinedIcon fontSize="inherit" />,
    info: <InfoOutlinedIcon fontSize="inherit" />
  },
  gutterBottom: false,
  shouldHaveClose: false,
  shouldHaveCustomAction: false,
  customAction: null
};

AlertBanner.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  severity: PropTypes.string.isRequired,
  iconMapping: PropTypes.shape({}),
  gutterBottom: PropTypes.bool,
  shouldHaveClose: PropTypes.bool,
  shouldHaveCustomAction: PropTypes.bool,
  customAction: PropTypes.shape({})
};

export default AlertBanner;
