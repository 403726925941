import axios from 'axios';
import i18n from 'i18next';
import { AXIOS_CONFIG, EMAIL_ADDRESS } from '../../../../shared/constants';
import ThunkError from '../../../shared/errors/ThunkError';
import { set as setFeedback } from '../../reducers/feedback';

const changePassword = (data) => async (dispatch) => {
  const { id, oldPassword, newPassword } = data;
  const instance = axios.create(AXIOS_CONFIG);

  try {
    await dispatch(setFeedback({}));
    await instance.patch(`/api/u/${id}/passwd`, {
      data: {
        oldPassword,
        newPassword
      }
    });
  } catch (e) {
    if (e && e.response && e.response.data) {
      const { data: message } = e.response;
      throw new ThunkError(message);
    }
    throw new ThunkError(i18n.t('error.async.unableToChangePassword', { email: EMAIL_ADDRESS }));
  }
};

export default changePassword;
