import { Link } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';
import CookieConsentComponent from 'react-cookie-consent';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  container: {
    background: 'rgb(67,108,251) !important',
    borderTopLeftRadius: `${theme.spacing(0.5)} !important`,
    borderTopRightRadius: `${theme.spacing(0.5)} !important`,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  },
  content: {
    color: `${theme.palette.background.default} !important`,
    margin: `${theme.spacing(1.75)} !important`
  },
  button: {
    color: `${theme.palette.background.default} !important`,
    background: 'rgb(12, 71, 180) !important',
    borderRadius: `${theme.spacing(0.5)} !important`,
    fontWeight: 900,
    marginTop: '0 !important',
    marginLeft: `${theme.spacing(1.75)} !important`,
    marginRight: `${theme.spacing(1.75)} !important`,
    marginBottom: `${theme.spacing(1.75)} !important`,
    padding: `${theme.spacing(0.75, 2)} !important`
  }
});

const CookieConsent = withTranslation()(
  withStyles(styles)(({ classes, t, children }) => (
    <CookieConsentComponent
      location="bottom"
      buttonText={t('cookieConsent.close')}
      cookieName="cookieConsent"
      containerClasses={classes.container}
      contentClasses={classes.content}
      buttonClasses={classes.button}
      expires={365} // days
    >
      {children}
    </CookieConsentComponent>
  ))
);

CookieConsent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])).isRequired
};

const CookieConsentLink = styled(Link)(({ theme }) => ({
  color: `${theme.palette.offwhite.main} !important`,
  textDecoration: 'underline'
}));

export { CookieConsent, CookieConsentLink };
