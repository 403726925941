import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeRepo, setEmail, setName, setUsername } from '../../store/reducers/user';
import { data, feedback, user } from '../../store/selectors';
import changePassword from '../../store/thunks/user/changePassword';
import getUserSettings from '../../store/thunks/user/getUserSettings';
import patchUserRepoLogs from '../../store/thunks/user/log/patchUserRepoLogs';
import patchUserSettings from '../../store/thunks/user/patchUserSettings';
import deleteUserRepo from '../../store/thunks/user/repo/deleteUserRepo';
import requestPasswordReset from '../../store/thunks/user/requestPasswordReset';
import SettingsPage from './SettingsPage';

const mapStateToProps = (state) => ({
  user: user(data(user(state))),
  feedback: data(feedback(state))
});

const mapDispatchToProps = (dispatch) => {
  const thunks = bindActionCreators(
    { changePassword, getUserSettings, patchUserSettings, patchUserRepoLogs, deleteUserRepo, requestPasswordReset },
    dispatch
  );
  return {
    ...thunks,
    setUsername: (username) => dispatch(setUsername(username)),
    setName: (name) => dispatch(setName(name)),
    setEmail: (email) => dispatch(setEmail(email)),
    removeRepo: (id) => dispatch(removeRepo(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
