import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PasswordResetPageContainer from '../auth/PasswordResetPageContainer';
import SigninPage from '../auth/SigninPage';
import SignupPage from '../auth/SignupPage';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import LandingPageContainer from '../landing-page/LandingPageContainer';
import PrivacyPolicy from '../legal/PrivacyPolicy';
import TermsAndConditions from '../legal/TermsAndConditions';
import SettingsPageContainer from '../settings/SettingsPageContainer';
import UserPageContainer from '../user/UserPageContainer';
import AppLayout from './app-layout/AppLayout';
import CoockieConsent from './cookie-consent/CoockieConsent';
import PageContainer from './page-container/PageContainer';
import ScrollToTop from './scroll-to-top/ScrollToTop';

// TODO:
// [ ] error page for pages with no route
// [ ] cookie consent
// [ ] change password route implementation

const AppRouter = () => (
  <Router>
    <ScrollToTop>
      <AppLayout>
        <Header />
        <PageContainer>
          <Routes>
            <Route path="/" element={<LandingPageContainer />} />
            <Route path="/reset/:id" element={<PasswordResetPageContainer />} />
            <Route path="/signin" element={<SigninPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/settings" element={<SettingsPageContainer />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="*" element={<UserPageContainer />} />
          </Routes>
        </PageContainer>
        <Footer />
        <CoockieConsent />
      </AppLayout>
    </ScrollToTop>
  </Router>
);

export default AppRouter;
