import axios from 'axios';
import i18n from 'i18next';
import { AXIOS_CONFIG, EMAIL_ADDRESS } from '../../../../shared/constants';
import ThunkError from '../../../shared/errors/ThunkError';
import { set as setFeedback } from '../../reducers/feedback';

const resetPassword = (data) => async (dispatch) => {
  const { email, resetCode, password } = data;
  const instance = axios.create(AXIOS_CONFIG);

  try {
    await dispatch(setFeedback({}));
    await instance.patch(`/api/reset/${resetCode}`, {
      data: {
        email,
        password
      }
    });
  } catch (e) {
    if (e && e.response && e.response.data) {
      const { data: message } = e.response;
      throw new ThunkError(message);
    }
    throw new ThunkError(i18n.t('error.async.unableToResetPassword', { email: EMAIL_ADDRESS }));
  }
};

export default resetPassword;
