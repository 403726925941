import { List } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import RepoItem from './RepoItem';

// sort by repos lastActivityAt value
const RepoItemList = ({ repos, onClick }) => (
  <List sx={{ width: '100%', overflow: 'auto', bgcolor: 'background.paper', maxWidth: '75vw', maxHeight: '75vh' }}>
    {repos
      .sort((rx, ry) => ry.lastActivityAt - rx.lastActivityAt)
      .map((repo) => (
        <RepoItem key={repo.id} onClick={onClick} {...repo} />
      ))}
  </List>
);

RepoItemList.defaultProps = {
  repos: []
};

RepoItemList.propTypes = {
  repos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number
    })
  ),
  onClick: PropTypes.func.isRequired
};

export default RepoItemList;
