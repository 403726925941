import { Avatar } from '@mui/material';
import { styled } from '@mui/system';
import { BP_MD, BP_SM } from '../../../../shared/constants';

export const RepoProviderAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  [theme.breakpoints.down(BP_MD)]: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  [theme.breakpoints.down(BP_SM)]: {
    width: theme.spacing(8),
    height: theme.spacing(8)
  }
}));
