import { Link as MuiLink, Typography as MuiTypography } from '@mui/material';
import { styled } from '@mui/system';
import { BP_MOBILE_LANDSCAPE } from '../../../shared/constants';

export const Typography = styled(MuiTypography)(({ theme }) => ({
  [theme.breakpoints.down(BP_MOBILE_LANDSCAPE)]: {
    letterSpacing: '0.02em'
  }
}));

export const Link = styled(MuiLink)(() => ({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));
