import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Card, CardActions, CardContent, Dialog, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { generateContributionCountText } from '../contribution-calendar/ContributionCalendar';

const ContributionDialog = ({ t, onClose, open, title, commits }) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose}>
      <Card sx={{ padding: theme.spacing(1, 2), marginTop: theme.spacing(2) }}>
        <CardContent sx={{ padding: 0, margin: 0 }}>
          <Typography variant="h6" sx={{ fontWeight: '600', lineHeight: 'normal' }}>
            {title}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: '600', lineHeight: 'normal' }}>
            {generateContributionCountText(commits)}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button onClick={onClose} startIcon={<CloseRoundedIcon />} sx={{ width: '100%' }}>
            {t('userPage.close')}
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
};

ContributionDialog.defaultProps = {
  title: '',
  commits: 0
};

ContributionDialog.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  commits: PropTypes.number
};

export default withTranslation()(ContributionDialog);
