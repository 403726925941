import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { BP_MOBILE_PORTRAIT } from '../../../../shared/constants';

export const ContributionLegendListRootContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  width: '100%',
  [theme.breakpoints.down(BP_MOBILE_PORTRAIT)]: {
    flexDirection: 'column',
    alignItems: 'end',
    marginRight: theme.spacing(1)
  },
}));

export const ContributionLegendListContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  alignItems: 'center'
}));
