import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getUserRepo from '../../store/thunks/user/log/getUserRepo';
import postUserRepoLogs from '../../store/thunks/user/log/postUserRepoLogs';
import listRepo from '../../store/thunks/user/repo/listRepo';
import UserPage from './UserPage';

const mapDispatchToProps = (dispatch) => bindActionCreators({ listRepo, postUserRepoLogs, getUserRepo }, dispatch);

export default connect(null, mapDispatchToProps)(UserPage);
