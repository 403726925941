import { Divider } from '@mui/material';
import React from 'react';
import Copyright from './copyright/Copyright';
import Credits from './credits/Credits';
import { Footer as FooterStyled } from './Footer.styles';
import LinkList from './link-list/LinkList';
import SnsList from './sns-list/SnsList';
import ThemedSection from './themed-section/ThemedSection';

const Footer = () => (
  <>
    <Divider />
    <FooterStyled>
      <ThemedSection />
      <Copyright />
      <SnsList />
      <LinkList />
      <Credits />
    </FooterStyled>
  </>
);

export default Footer;
