import { Button, InputAdornment, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { ALERT_SEVERITY_ERROR, ALERT_SEVERITY_SUCCESS } from '../../../shared/constants';
import { isNilOrEmpty } from '../../../shared/utils';
import { withAuth } from '../hocs/withAuth';
import { withProgress } from '../hocs/withProgress';
import { parseAuthFailureResponse, useAuthFailureResponse } from '../hooks/useAuthFailureResponse';
import AlertBanner from '../shared/AlertBanner';
import { FlexColumnContainer, FlexRowContainer, PageContainer, PageContentContainer } from '../shared/Common.styles';
import Link from '../shared/Link';
import RoundedButton from '../shared/RoundedButton';
import RoundedTextField from '../shared/RoundedTextField';
import { RememberMeCheckbox } from './AuthPage.styles';
import ResetPasswordDialog from './reset-password/ResetPasswordDialog';

// TODO:
// [x] use alert banner to be consistent throughout pages (e.g. settings page)
// [ ] refactor notification message and consolidate with async error message
// [ ] implement forgot password
// [ ] implement t&c and privacy policy

const AuthPage = (props) => {
  const {
    auth: { isAuthenticated },
    t,
    setProgress,
    signup,
    signin,
    requestPasswordReset
  } = props;
  const [state, setState] = useState({
    snackbar: null,
    showPassword: false,
    showResetPasswordDialog: false,
    emailValidationError: false,
    passwordValidationError: false
  });
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(t('path.frontend.settings'));
    }
  }, [isAuthenticated, navigate, t]);

  useAuthFailureResponse((authFailureResponse) => {
    if (isNilOrEmpty(authFailureResponse)) {
      // no auth failure response found
      return;
    }
    setState({
      ...state,
      snackbar: parseAuthFailureResponse(authFailureResponse, t)
    });
  });

  const handleShowPassword = (e) => {
    if (e) {
      e.preventDefault();
    }
    setState({
      ...state,
      showPassword: !state.showPassword
    });
  };

  const handleEmailChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    setState({
      ...state,
      emailValidationError: false
    });
  };

  const handlePasswordChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    setState({
      ...state,
      passwordValidationError: false
    });
  };

  const handleSubmit = (e) => {
    // name validity check only applies for signup
    const isValidEmail = validator.isEmail(emailRef.current.value);
    const isPasswordStrong =
      (signin && !isNilOrEmpty(passwordRef.current.value)) || (signup && validator.isStrongPassword(passwordRef.current.value));

    if (!isValidEmail || !isPasswordStrong) {
      setState({
        ...state,
        emailValidationError: !isValidEmail,
        passwordValidationError: !isPasswordStrong
      });
      e.preventDefault();
    }
  };

  const getAuthButtonErrorStyle = (error) => {
    if (error) {
      return {
        '& .MuiFilledInput-root': {
          border: '2px solid rgba(193, 25, 21, 0.75)',
          backgroundColor: 'rgba(193, 25, 21, 0.10)'
        }
      };
    }
    return {};
  };

  const getPasswordHelperText = () => (signup ? t('error.account.signupPassword') : t('error.account.signinPassword'));

  const handleResetPasswordClick = () => {
    setState({
      ...state,
      showResetPasswordDialog: true
    });
  };

  const handleResetPasswordDialogClose = (email) => {
    setState((pstate) => ({ ...pstate, showResetPasswordDialog: false }));

    if (isNilOrEmpty(email)) {
      return;
    }

    setProgress(true);
    requestPasswordReset({ email })
      .then(() => {
        setState((pstate) => ({
          ...pstate,
          snackbar: {
            message: t('notification.resetEmailSuccess'),
            severity: ALERT_SEVERITY_SUCCESS
          },
          showResetPasswordDialog: false
        }));
      })
      .catch((e) => {
        setState((pstate) => ({
          ...pstate,
          snackbar: {
            message: e.message,
            severity: ALERT_SEVERITY_ERROR
          },
          showResetPasswordDialog: false
        }));
      })
      .finally(() => {
        setProgress(false);
      });
  };

  const onTermsAndConditionsClicked = () => {
    navigate(t('path.frontend.termsAndConditions'));
  };

  const onPrivacyPolicyClicked = () => {
    navigate(t('path.frontend.privacyPolicy'));
  };

  return (
    !isAuthenticated && (
      <PageContainer>
        <PageContentContainer>
          {state.snackbar && <AlertBanner gutterBottom message={state.snackbar.message} severity={state.snackbar.severity} />}

          {/* reset password dialog */}
          <ResetPasswordDialog onClose={handleResetPasswordDialogClose} open={state.showResetPasswordDialog} />

          {/* authentication header */}
          <Typography variant="h5" gutterBottom sx={{ fontWeight: '600', marginBottom: theme.spacing(5) }}>
            {signup ? t('auth.signup') : t('auth.signin')}
          </Typography>

          <form
            action={signup ? t('path.backend.auth.local.signup') : t('path.backend.auth.local.signin')}
            method="POST"
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
          >
            {/* email input field */}
            <FlexColumnContainer sx={{ marginBottom: theme.spacing(2) }}>
              <Typography variant="h6" sx={{ fontWeight: '600' }}>
                {t('common.email')}
              </Typography>
              <RoundedTextField
                inputRef={emailRef}
                name="email"
                autoComplete="email"
                helperText={state.emailValidationError ? t('error.account.email') : ''}
                sx={getAuthButtonErrorStyle(state.emailValidationError)}
                onChange={handleEmailChange}
              />
            </FlexColumnContainer>

            {/* password input field */}
            <FlexColumnContainer sx={{ marginBottom: signin ? 0 : theme.spacing(2) }}>
              <FlexRowContainer sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: '600' }}>
                  {t('common.password')}
                </Typography>
                {signin && (
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  <Link
                    onClick={handleResetPasswordClick}
                    text={t('auth.forgotPassword')}
                    variant="body2"
                    sx={{ fontWeight: '600', color: 'rgb(0, 0, 0, 0.5)' }}
                  />
                  /* eslint-enable-next-line jsx-a11y/anchor-is-valid */
                )}
              </FlexRowContainer>
              <RoundedTextField
                inputRef={passwordRef}
                name="password"
                autoComplete="current-password"
                helperText={state.passwordValidationError ? getPasswordHelperText() : ''}
                sx={getAuthButtonErrorStyle(state.passwordValidationError)}
                type={state.showPassword ? 'text' : 'password'}
                onChange={handlePasswordChange}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disableFocusRipple
                        disableRipple
                        onClick={handleShowPassword}
                        sx={{
                          textTransform: 'none',
                          color: '#000',
                          fontWeight: '600',
                          '&:hover': { backgroundColor: 'inherit' }
                        }}
                      >
                        {state.showPassword ? t('auth.hide') : t('auth.show')}
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            </FlexColumnContainer>

            {signin && (
              <FlexRowContainer
                sx={{
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                  justifyContent: 'end',
                  alignItems: 'center'
                }}
              >
                <RememberMeCheckbox color="default" name="remember_me" value="yes" sx={{ color: 'rgb(0, 0, 0, 0.5)' }} />
                <Typography variant="body2" sx={{ fontWeight: '600', color: 'rgb(0, 0, 0, 0.5)' }}>
                  {t('auth.rememberMe')}
                </Typography>
              </FlexRowContainer>
            )}

            <RoundedButton
              text={signup ? t('auth.signup') : t('auth.signin')}
              sx={{ margin: theme.spacing(2, 0) }}
              dataButton="auth-submit-button"
              submit
            />

            {signup && (
              <Typography
                sx={{
                  color: 'rgb(0, 0, 0, 0.5)',
                  padding: theme.spacing(0, 0.5),
                  marginBottom: theme.spacing(16)
                }}
              >
                {t('auth.agreement.message')}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  onClick={onTermsAndConditionsClicked}
                  text={t('footer.termsAndConditions')}
                  gutterBottom
                  variant="body1"
                  sx={{ fontWeight: '500', color: 'rgb(193, 25, 21, 0.75)' }}
                />
                {/* eslint-enable-next-line jsx-a11y/anchor-is-valid */}
                {t('auth.agreement.and')}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  onClick={onPrivacyPolicyClicked}
                  text={t('footer.privacyPolicy')}
                  gutterBottom
                  variant="body1"
                  sx={{ fontWeight: '500', color: 'rgb(193, 25, 21, 0.75)' }}
                />
                {/* eslint-enable-next-line jsx-a11y/anchor-is-valid */}
                {t('auth.agreement.period')}
              </Typography>
            )}
          </form>
        </PageContentContainer>
      </PageContainer>
    )
  );
};

AuthPage.defaultProps = {
  auth: {
    isAuthenticated: false
  },
  signup: false,
  signin: false
};

AuthPage.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool
  }),
  t: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired,
  signup: PropTypes.bool,
  signin: PropTypes.bool,
  requestPasswordReset: PropTypes.func.isRequired
};

export default withProgress()(withAuth()(withTranslation()(AuthPage)));
