import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FlexColumnContainer } from '../../../shared/Common.styles';
import { ProviderButtonIcon } from './ProviderButton.styles';

const ProviderButton = ({ iconSrc, iconAlt, onClick, disabled }) => (
  <Button onClick={onClick} disabled={disabled}>
    <FlexColumnContainer sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <ProviderButtonIcon variant="square" src={iconSrc} alt={iconAlt} />
      <Typography noWrap gutterBottom variant="overline" component="p">
        {iconAlt}
      </Typography>
    </FlexColumnContainer>
  </Button>
);

ProviderButton.defaultProps = {
  iconSrc: null,
  iconAlt: null,
  onClick: () => {},
  disabled: false
};

ProviderButton.propTypes = {
  iconSrc: PropTypes.string,
  iconAlt: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default ProviderButton;
