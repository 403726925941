import VerifiedIcon from '@mui/icons-material/Verified';
import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { FlexRowContainer } from '../../shared/Common.styles';
import { GridColumn, GridRow } from './PricingTable.styles';

const PricingTable = ({ t, sx, isSingle, isMulti }) => (
  <GridRow sx={sx}>
    {/* single plan pricing column */}
    <GridColumn>
      <FlexRowContainer sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t('settings.single')}
        </Typography>
        &nbsp;
        {isSingle && <VerifiedIcon />}
      </FlexRowContainer>
      <Typography variant="h6">{t('settings.pricing.price.single')}</Typography>
      <Typography variant="h6">&nbsp;</Typography>
      <Typography variant="button" sx={{ textAlign: 'center', fontWeight: 300, lineHeight: 1.25, opacity: 0.65 }}>
        {t('settings.pricing.description.single')}
      </Typography>
    </GridColumn>
    <Divider orientation="vertical" flexItem sx={{ opacity: 0.5, fontSize: 'small' }} />
    {/* multi plan pricing column */}
    <GridColumn>
      <FlexRowContainer sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t('settings.multi')}
          &nbsp;
          {isMulti && <VerifiedIcon />}
        </Typography>
      </FlexRowContainer>
      <FlexRowContainer sx={{ alignItems: 'baseline ' }}>
        <Typography variant="h6">{t('settings.pricing.price.multi')}</Typography>
        &nbsp;
        <Typography variant="body2" sx={{ opacity: 0.65 }}>
          {t('settings.pricing.monthlyUnit')}
        </Typography>
      </FlexRowContainer>
      <Typography variant="h6">&nbsp;</Typography>
      <Typography variant="button" sx={{ textAlign: 'center', fontWeight: 300, lineHeight: 1.25, opacity: 0.65 }}>
        {t('settings.pricing.description.multi')}
      </Typography>
    </GridColumn>
  </GridRow>
);

PricingTable.defaultProps = {
  sx: {},
  isSingle: false,
  isMulti: false
};

PricingTable.propTypes = {
  t: PropTypes.func.isRequired,
  sx: PropTypes.shape({}),
  isSingle: PropTypes.bool,
  isMulti: PropTypes.bool
};

export default withTranslation()(PricingTable);
