import React, { useCallback, useState } from 'react';
import { getWrappedComponentDisplayName } from '../../../shared/utils';
import ProgressSpinner from '../shared/ProgressSpinner';

const withProgress = () => (WrappedComponent) => {
  const WithProgress = (props) => {
    const [open, setOpen] = useState(false);

    const setProgress = useCallback((progress) => {
      setOpen(progress);
    }, []);

    return (
      <>
        <ProgressSpinner open={open} />
        <WrappedComponent setProgress={setProgress} {...props} />
      </>
    );
  };

  WithProgress.displayName = `WithProgress(${getWrappedComponentDisplayName(WrappedComponent)})`;

  return WithProgress;
};

export { withProgress };
