import { createSlice } from '@reduxjs/toolkit';

// state to contain app feedback (info, warn, error, etc) to display in UI

const initialState = {
  data: {}
};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    set(state, action) {
      state.data = action.payload;
    }
  }
});

export const { set } = slice.actions;
export default slice.reducer;
