import { Avatar, Box, Button } from '@mui/material';
import { styled } from '@mui/system';

export const RoundedButton = styled(Button)(({ theme }) => ({
  border: '2px solid rgba(66, 66, 66, 1)',
  borderRadius: theme.spacing(4),
  fontWeight: 500,
  padding: theme.spacing(0.5, 0),
  width: '100%',
  textTransform: 'none',
  '&:hover': {
    border: '2px solid rgba(66, 66, 66, 1)',
    backgroundColor: '#e9e7e9'
  },
  color: 'inherit'
}));

export const RoundedButtonContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const RoundedButtonIcon = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  marginRight: theme.spacing(1)
}));
