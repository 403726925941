import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { BP_MD } from '../../../../shared/constants';
import { getFirstLetter, isNilOrEmpty } from '../../../../shared/utils';
import { useScreenSizeDetector } from '../../hooks/useScreenSizeDetector';
import { ProfileRepoFilterChip, ProfileRepoFilterRootContainer } from './ProfileRepoFilter.styles';

const getChipAvatar = (alt, src) =>
  isNilOrEmpty(src) ? (
    <Avatar alt={alt}>{getFirstLetter(alt)}</Avatar>
  ) : (
    <Avatar alt={alt} src={src}>
      {getFirstLetter(alt)}
    </Avatar>
  );

const ProfileRepoFilter = ({ data, index: selectedIndex, onFilterClick }) => {
  const chipSize = useScreenSizeDetector(BP_MD) ? 'small' : 'medium';

  return (
    <ProfileRepoFilterRootContainer>
      {data.map(({ name, pathWithNamespace, avatarUrl }, index) => (
        <ProfileRepoFilterChip
          key={pathWithNamespace}
          variant={index === selectedIndex ? 'filled' : 'outlined'}
          size={chipSize}
          avatar={getChipAvatar(name, avatarUrl)}
          label={name}
          onClick={onFilterClick(index)}
        />
      ))}
    </ProfileRepoFilterRootContainer>
  );
};

ProfileRepoFilter.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      logs: PropTypes.arrayOf(PropTypes.number)
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  onFilterClick: PropTypes.func.isRequired
};

export default ProfileRepoFilter;
