import { Avatar, Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const ThemedSection = ({ t }) => (
  <Box>
    <Avatar variant="square" alt={t('tree.text')} src={t('tree.logo')} sx={{ marginBottom: '4px' }} />
  </Box>
);

ThemedSection.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(ThemedSection);
