import PropTypes from 'prop-types';
import React from 'react';
import { BackdropStyled, CircularProgressStyled } from './ProgressSpinner.styles';

const ProgressSpinner = ({ open }) => (
  <BackdropStyled open={open}>
    <CircularProgressStyled color="inherit" size={30} thickness={5} />
  </BackdropStyled>
);

ProgressSpinner.defaultProps = {
  open: false
};

ProgressSpinner.propTypes = {
  open: PropTypes.bool
};

export default ProgressSpinner;
